// importação de pacotes
import { isCPF } from 'brazilian-values';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AiOutlineSend } from 'react-icons/ai';
import { FaTrashAlt } from 'react-icons/fa';
import { GrDocumentMissing } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { history } from '../../../../helpers';

// importação de estilização
import * as S from './styles';
import {
  AccordionIcon,
  ButtonsForm,
  Container,
  ContainerAccordion,
  ContainerBodyAccordion,
  ContainerButton,
  ContainerForm,
  ContainerInputs,
  ContainerLabelInput,
  ContainerListActions,
  ContainerLoaderFastActions,
  ContainerPage,
  ErrorInput,
  FormAdmContainer,
  Header,
  ImageDocument,
  ImageDocumentZoom,
  ItemDescription,
  ItemList,
  LabelInput,
  Line,
  SelectDiv,
} from './styles';

import {
  ContainerSelectModal,
  IconButton,
  Main,
  SelectTitle,
  TextAreaModal,
  ValueCheck,
} from '../../QueuePage/styles';

import GlobalStyle from '../../styles/globalStyles';

//importação de componentes
import Alert from '../../../../components/Alert';
import { CustomRadioGroup } from '../../../../Spread/components/ui/webform';
import Modal from '../../../DialogModal';
import Loading from '../../../Loading';
import Accordion from '../../Accordion';
import CustomButton from '../../CustomButton';
import CustomTitle from '../../CustomTitle';
import GenericSelect from '../../GenericSelect';
import Loader from '../../Loader';
import ModalAlert from '../../ModalAlert';
import ModalAlertLink from '../../ModalAlertLink';
import ModalDialog from '../../ModalDialog';
import { CheckBox } from '../FormCheckBox/index';
import FormInput from '../FormInput';
import SelectGroup from '../SelectLocalidade/SelectGroup';
import InputMask from './InputMask';

//importação de actions
import {
  acionamentoActions,
  acionamentoActionsVonage,
  actionDeleteDocs,
  actionForwarding,
  alertActions,
  alertContingenciaActions,
  modalAlertActions,
  userActions,
} from '../../../../actions';

// importação de service
import { acionamentoService } from '../../../../services/acionamento.service';
import { acionamentoVonageService } from '../../../../services/acionamentoVonage.service';

//importação de utils do form
import Video from '../../../../icons/video.svg';
import { isMinor } from '../../../../utils/utils';
import { getNavigatorInfos } from '../../utils/utils';
import {
  Nacionality,
  Sexo,
  initialValuesFormAdm,
  objectForFoward,
  removeReason,
  supportReason,
} from './config';

import { productsTriggerQueue } from './constants';
import { EisteinClinicAndCareForm } from './views';

import { PurchaseCodeField } from './subcomponents/purchaseCodeField';
import { ScrollToError } from './subcomponents/scrollToError';

import { generateSchema } from './utils/generateSchema';
import { getExclusiveFieldsOrder } from './utils/resolveExclusiveFields';
import {
  healthOperatorOptions,
  isPatientEligibleOptions,
} from './utils/selectOptions';

export default function FormAdm({ typeForm, fatherState }) {
  const dispatch = useDispatch();
  const location = useLocation();
  //-----------------------------Declaração de states e hooks---------------------------------------------------------------------------
  const socketInfos = useSelector((state) => state.socketInfos.socketInfos);

  const [itemFoward, setItemFoward] = useState({});
  const [openModalSupport, setOpenModalSupport] = useState(false);
  const [suportError, setSuportError] = useState(false);
  const [reasonSupport, setReasonSupport] = useState(0);
  const [reenvioLink, setReenvioLink] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [openReenvio, setOpenReenvio] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [reasonRemove, setReasonRemove] = useState(0);
  const [itemDelete, setItemDelete] = useState({});
  const [openModalRemoveImage, setOpenModalRemovaImage] = useState(false);
  const [sendMailValues, setSendMailValues] = useState({});
  const [openImageDocument, setOpenImageDocument] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [preLoad, setPreLoad] = useState(false);
  const [pushCallModal, setPushCallModal] = useState(false);
  const [pushCallLoading, setPushCallLoading] = useState(false);
  const [pushCallTitle, setPushCallTitle] = useState('');
  const [pushCallMessage, setPushCallMessage] = useState('');
  const [pushCallError, setPushCallError] = useState(false);
  // const [tryLoad, setTryLoad] = useState(false);
  // const [loadingImage, setLoadingImage] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [patientNetworkError, setPatientNetworkError] = useState(false);
  const [signUpDocuments, setSignUpDocuments] = useState([]);
  const { convenios } = useSelector((state) => state.convenios);
  // const { queues } = useSelector((state) => state.queues);
  // const { products } = useSelector((state) => state.products);
  const { user } = useSelector((state) => state.authentication);

  const [loading, setLoading] = useState(false);
  const [inAttendance, setInAttendance] = useState(true);
  const { url } = useSelector((state) => state.alertContingencia);
  const newModalAlert = useSelector((state) => state.modalAlert);
  const { messageModalAlert, openModalAlert, typeModalAlert } = newModalAlert;
  //toast vermelho
  const { message, open, type } = useSelector((state) => state.alert);
  const [readyForDelete, setReadyForDelete] = useState(true);
  const [getFormValues, setFormValues] = useState({});
  const [isSocialNameChecked, setIsSocialNameChecked] = useState(false);
  //----------------------------- Declaração de Funções ----------------------------------------------

  const clickedInfoAttendance = useSelector((state) => state.editAcionamento);

  const acionamentoConvenio = useSelector((state) => {
    return state?.editAcionamento?.editAcionamento?.convenio;
  });

  const showExclusiveFields = useMemo(
    () =>
      getExclusiveFieldsOrder({
        isUpaDigital: clickedInfoAttendance?.editAcionamento?.isUpaDigital,
        convenio: acionamentoConvenio,
      }),
    [acionamentoConvenio]
  );

  const formOnLoad = (clickedInfoAttendance) => {
    const { editAcionamento } = clickedInfoAttendance;
    let editionStore = JSON.parse(localStorage.getItem('userEdition'));
    let editionForm = {};
    if (editionStore) {
      editionForm = {
        ...editAcionamento,
        ...editionStore,
        edit: true,
        isVonage: true,
      };
      return editionForm;
    }
    return editAcionamento;
  };
  let editAcionamento = formOnLoad(clickedInfoAttendance);
  let returnReception = formOnLoad(clickedInfoAttendance)?.return_to_reception;

  const fetchEncounter = useCallback(async () => {
    try {
      const data = await acionamentoVonageService.findEncounterById(
        editAcionamento._id
      );
      setEncounter(data.data);
    } catch (e) {
      setPushCallError(false);
    }
  }, []);

  useEffect(() => {
    fetchEncounter();
  }, [fetchEncounter]);

  const values = initialValuesFormAdm;
  const ModalDeleteDocs = ModalDialog;
  const setMask = (mask) => {
    if (mask === 'cpf') {
      return '999.999.999-99';
    }
    if (mask === 'phone') {
      return '(99) 999999999';
    }

    if (mask === 'nascimento') {
      return '99/99/9999';
    } else {
      return '************************************************************';
    }
  };
  const formValues = (editAcionamento, values = {}) => {
    let editionStore = JSON.parse(localStorage.getItem('userEdition'));
    let editionForm = {};
    if (editAcionamento.edit || editionStore) {
      if (editionStore) {
        editionForm = {
          ...editionStore,
          ...editionForm,
          edit: true,
          isVonage: true,
        };
      }

      let nacionalityTranslate = editionForm?.nationality
        ? translateNationality(editionForm.nationality)
        : defineNationality(editionForm?.document, editionForm?.birthDate);
      editionForm.nationality = nacionalityTranslate;
      editionForm.checkSocialName = editionForm.socialName ? true : false;
      return editionForm;
    } else {
      let { browserName, fullVersion, so } = getNavigatorInfos(window);
      values.deviceData = {
        deviceType: '',
        OS: so,
        navigator: browserName,
        route: '/create',
        accessChannel: 'web',
        appVersion: fullVersion,
        deviceBrand: 'manual-central',
      };
      return values;
    }
  };

  const translateNationality = (nationality) => {
    const brazilian = ['brasileiro', 'brasileira'];
    const foreigner = ['estrangeiro', 'estrangeira'];
    if (
      brazilian.some(
        (brazilian) => brazilian === nationality?.trim().toLowerCase()
      )
    )
      return 'B';
    if (
      foreigner.some(
        (foreigner) => foreigner === nationality?.trim().toLowerCase()
      )
    )
      return 'E';
    return nationality;
  };

  const updatedTime = () => {
    let replacedDate = new Date().toISOString();
    return replacedDate;
  };

  let getDate = (str) => moment(str, 'YYYY-MM-DD').format('DD/MM/YYYY');

  const valueCheck = (value) => {
    if (value === undefined) return false;
    return value !== '';
  };

  const checkCountry = (values) => {
    if (values.country === 'Brasil') {
      if (valueCheck(values.state) && valueCheck(values.city)) {
        return true;
      }
    }
    if (values.country === 'United States of America') {
      if (valueCheck(values.state)) {
        return true;
      }
    }
    if (
      values.country !== 'United States of America' &&
      values.country !== 'Brasil'
    ) {
      return true;
    }
  };

  const handleSubmit = (values, resetForm) => {
    if (values.encounterHealthOperator === 'Outros') {
      values.encounterHealthOperator = values.encounterHealthOperatorName;
    }

    fatherState.setState({ blockStatusReset: true });
    let { name, lastName, socialName, email, email2 } = values;
    setSendMailValues({
      name: name,
      lastName: lastName,
      socialName: socialName,
      email: email,
      emai2: email2,
    });

    if (values.edit) {
      if (checkCountry(values)) {
        let confirmedTime = updatedTime();
        let age = getDate(values.birthDate);

        if (onNumberCard(values)) {
          dispatch(
            alertActions.error(
              'Dados inválidos, Verifique o campo da Carteirinha. '
            )
          );
        } else {
          if (!isMinor(age) && values.document?.length < 1) {
            dispatch(
              alertActions.error(
                'Dados inválidos, Verifique o campo da documento. '
              )
            );
          } else {
            dispatch(
              acionamentoActions.confirmedAcionamentosNovo(
                { ...values, confirmedTime },
                setLoading
              )
            );
            dispatch(acionamentoActions.clearAcionamentos());
            setLoading(true);
            this.handleCloseModalConfirmacao();
          }
        }
      }
    } else {
      if (checkCountry(values)) {
        //values.truclinic == true || values.vonage == true
        if (true) {
          if (isMinor(values.birthDate) === true) {
            //values.truclinic

            if (true) {
              dispatch(userActions.registerNovo({ ...values }, setLoading));
              setLoading(true);
              resetForm();
            } else {
              dispatch(userActions.registerVonage({ ...values }, setLoading));
              setLoading(true);
              resetForm();
            }
          } else if (
            isMinor(values.birthDate) === false &&
            values.document.length > 5
          ) {
            //values.truclinic
            if (true) {
              dispatch(userActions.registerNovo({ ...values }, setLoading));
              setLoading(true);
              resetForm();
            } else {
              dispatch(userActions.registerVonage({ ...values }, setLoading));
              setLoading(true);
              resetForm();
            }
          } else {
            dispatch(
              alertActions.error(
                'Dados inválidos, Verifique o campo de documento. '
              )
            );
          }
        } else {
          dispatch(alertActions.error('Verifique o tipo de plataforma. '));
        }
      }
    }
  };

  // const handleResendDocuments = async (values) => {
  //   setLoading(true);
  //   await acionamentoService
  //     .reSendDocuments(values)
  //     .then(() => {
  //       setLoading(false);
  //       dispatch(alertActions.success('Reenvio realizado com sucesso '));
  //     })
  //     .catch((error) => {
  //       setLoading(false);

  //       dispatch(alertActions.error(error.message));
  //     });
  // };

  const handleCloseAlertLink = () => {
    dispatch(alertContingenciaActions.clear());
    history.push('/adm');
  };

  async function ResendEmail() {
    try {
      let { name, lastName, socialName, email, email2, url } = sendMailValues;

      await acionamentoService
        .sendEmail(name, lastName, socialName, email, email2, url)
        .then(dispatch(alertActions.success('Link reenviado por e-mail!.')));
    } catch (erro) {
      dispatch(alertActions.error(erro.message));
    }
  }

  const handleOpenReenvio = () => {
    setReenvioLink(editAcionamento.urlAttendanceVonage);
    setSendMailValues({
      name: editAcionamento.name,
      lastName: editAcionamento.lastName,
      socialName: editAcionamento.socialName,
      email: editAcionamento.email,
      emai2: editAcionamento.email2,
      url: editAcionamento.urlAttendanceVonage,
    });
    setOpenReenvio(true);
  };

  const textAreaCopy = useRef(null);
  const handleCopyReenvio = (e) => {
    textAreaCopy.current.select();
    document.execCommand('copy');
  };

  const handleCloseReenvio = () => {
    setOpenReenvio(false);
  };

  const handleCloseNewModal = () => {
    if (messageModalAlert?.includes('redirecionado')) {
      history.push('/adm');
      dispatch(modalAlertActions.clear());
    } else {
      dispatch(modalAlertActions.clear());
      history.push('/adm/acionamento/new');
    }
  };

  async function sendEmail() {
    try {
      let { name, lastName, socialName, email, email2 } = sendMailValues;

      await acionamentoService
        .sendEmail(name, lastName, socialName, email, email2, url)
        .then(
          dispatch(alertActions.success('Link enviado por e-mail com sucesso!'))
        );
    } catch (erro) {
      dispatch(alertActions.error('Falha ao enviar link por e-mail'));
    }
  }

  function handleBack() {
    dispatch(acionamentoActionsVonage.clearAcionamentos());
    history.push('/adm');
    window.scroll(0, 0);
  }

  const setPlaceHolderDocEdit = (document, nationality) => {
    if ((document && isCPF(document)) || nationality === 'B') return 'CPF';
    if (!(document && isCPF(document)) && nationality === 'E')
      return 'Passaporte';
    return 'Documento';
  };

  const setPlaceHolderDocCreate = (value) => {
    if (value === 'B') {
      return 'CPF';
    } else if (value === 'E') {
      return 'Passaporte';
    }
    return 'Documento';
  };

  const objConvenio = (value) => {
    if (value) {
      return value.map((element) => {
        element.label = element.descricao;
        element.value = `${element.id}|${element.descricao}`;
        return { ...element };
      });
    }
  };

  // const objQueues = (value) => {
  //   if (value) {
  //     return value.map((element) => {
  //       element.label = element.fila;
  //       element.value = `${element.idFila}`;
  //       return { ...element };
  //     });
  //   }
  // };

  // const objProducts = (value) => {
  //   if (value) {
  //     return value.map((element) => {
  //       element.label = element.descricao;
  //       element.value = `${element.descricao}`;
  //       return { ...element };
  //     });
  //   }
  // };

  const handleFormatDate = (date) => {
    var convert = new Date(date);
    return moment(convert).isValid()
      ? moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
      : moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY');
  };
  const space = () => {
    return '60px';
  };

  const onNumberCard = (values) => {
    let idEnv = process.env.REACT_APP_NUMBER_CARD;
    idEnv = idEnv.split(',');
    values.numberCard = values.numberCard?.trim();
    const isEmptyAndRequiredNumberCard =
      values?.numberCard?.length == 0 && idEnv.includes(values?.convenio);
    return isEmptyAndRequiredNumberCard;
  };

  const maskDocumentEdit = (value) => {
    if (value) return value && isCPF(value) ? 'cpf' : '';

    return '';
  };

  const maskDocumentCreate = (values) => {
    return values === 'B' ? 'cpf' : '';
  };

  const handleOpenDocument = (src) => {
    setImageSrc(src);
    setOpenImageDocument(true);
  };

  const handleCloseDocument = () => {
    setImageSrc('');
    setOpenImageDocument(false);
  };

  const handleCloseModalSupport = () => {
    setReasonSupport(0);
    setOpenModalSupport(false);
  };

  const handleConfirmSupport = () => {
    const { data } = itemFoward;

    if (reasonSupport === '' || reasonSupport === 0) {
      setSuportError(true);
    } else {
      fatherState.setState({ blockStatusReset: true });

      setSuportError(false);
      fowardQueueItem(data, reasonSupport);
      setOpenModalSupport(false);
    }
  };

  const handleChangeReasonSupport = (e) => {
    setReasonSupport(e.target.value);
  };

  const fowardQueueItem = async (data, reason) => {
    const locallUser = JSON.parse(localStorage.getItem('user'));
    objectForFoward.origin.from = locallUser?.user?.id_perfil?.descricao;
    objectForFoward.originProfessional.name = locallUser.user.nome;
    objectForFoward.originProfessional.id = locallUser.user._id;
    data.reason = reason;
    data.form = true;
    const dataForFowarding = { ...data, ...objectForFoward };
    dispatch(actionForwarding.forwarding(dataForFowarding));
  };
  const handleAttendance = (data, setInAttendance) => {
    let editionTime = updatedTime();
    dispatch(
      acionamentoActionsVonage.attendanceAcionamentos(
        { ...data, editionTime },
        '',
        setInAttendance
      )
    );
  };

  const formatPushCallTime = (time) => {
    const date = new Date(time);
    const minutes =
      date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
    return `${date.getHours()}h${minutes}`;
  };

  const loadImg = () => {
    editAcionamento?.signUpDocuments?.map((el, index) => {
      handleGetUrlDocs(el)
        .then((result) => (el.urls = result))
        .then(() => {
          if (editAcionamento?.signUpDocuments.length - 1 === index) {
            setSignUpDocuments(editAcionamento?.signUpDocuments);
            setTimeout(() => {
              setLoadingDocuments(false);
            }, 1000);
          }
        })
        .catch((e) => {
          console.log(e);
          setPreLoad(false);
        });
    });
  };

  const handleGetUrlDocs = async (doc) => {
    let { signedURL } = await acionamentoActions.generateImageUrl(doc);
    return signedURL;
  };

  //----------------------------- Uso de hooks ----------------------------------------------
  useEffect(() => {
    dispatch(acionamentoActions.getAllConvenios());
    // dispatch(acionamentoActions.getAllQueuesRequisition());
    // dispatch(acionamentoActions.getAllProductsRequisition());
    loadImg();
    setReadyForDelete(editAcionamento?.signUpDocuments?.length > 0);

    return async () => {
      const action = fatherState.props.history.action;
      const pathname = location.pathname;
      const editAttendance = formValues(editAcionamento);
      if (
        (action === 'POP' || action === 'PUSH') &&
        pathname === '/adm/acionamento/new' &&
        !fatherState.state.blockStatusReset
      ) {
        localStorage.removeItem('userEdition');
        editAttendance.status = 'output';

        if (editAttendance?.isVonage) {
          await acionamentoActionsVonage.updateStatusAttendance({
            ...editAttendance,
            clear: true,
          });
        } else {
          localStorage.removeItem('userEdition');
          await acionamentoActionsVonage.updateStatusAttendance2({
            ...editAttendance,
            clear: true,
          });
        }
      }
    };
  }, []);

  useEffect(() => {
    const locallUser = JSON.parse(localStorage.getItem('user'));
    const userId = locallUser.user._id;

    const { documentKey, updateDescription, fullDocument } = socketInfos;
    const id = documentKey?._id;
    const eventType =
      updateDescription?.updatedFields?.currentEditionAttendanceInfo?.eventType;
    const stringForConditional = 'FINISH_RECEPCIONIST';
    const professionalIdForConditional = fullDocument?.originProfessional?.id;

    if (socketInfos.operationType === 'update') {
      if (
        id === editAcionamento._id &&
        eventType === stringForConditional &&
        professionalIdForConditional != userId
      ) {
        dispatch(modalAlertActions.clear());
        history.push('/adm');
      }
    }
    if (socketInfos.operationType === 'delete') {
      if (id === editAcionamento._id) {
        if (fullDocument?.statusUserInVonage === 'EMPTY') {
          setPatientNetworkError(true);
        } else {
          dispatch(modalAlertActions.clear());
          history.push('/adm');
        }
        if (showExclusiveFields) {
          const { _id } = getFormValues;
          const config = require('./config.js');
          deleteQueueItem(_id, config.userCancelmentUpa.value, getFormValues);
        }
      }
    }
  }, [socketInfos]);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleConfirmDelete = () => {
    const { id, data } = itemDelete;
    if (reasonRemove === '' || reasonRemove === 0) {
      setDeleteError(true);
    } else {
      setDeleteError(false);
      deleteQueueItem(id, reasonRemove, data);
      setOpenDelete(false);
    }
  };

  const deleteQueueItem = (id, reason, pacientData) => {
    dispatch(acionamentoActions.deleteAcionamentosNew(id, reason, pacientData));
  };

  const handleReason = (e) => {
    setReasonRemove(e.target.value);
  };

  const handleRemoveCancel = () => {
    setOpenModalRemovaImage(false);
  };

  const handleRemoveConfirm = () => {
    setLoading(true);
    let loggedUser = JSON.parse(window.localStorage.user);
    const userLogin = user;

    let docRemovedBy = {
      _id: loggedUser.user._id,
      email: loggedUser.user.email,
      nome: loggedUser.user.nome,
      login: loggedUser.user.login,
      telefone: loggedUser.user.telefone,
    };
    const docUpdate = (status) => {
      if (status) {
        delete editAcionamento.signUpDocuments;
      }
    };

    dispatch(
      actionDeleteDocs.deleteDocsUser(
        {
          user: {
            ...editAcionamento,
            userLogin,
            docRemovedBy,
            isDependent: false,
          },
        },
        docUpdate,
        setLoading,
        setSignUpDocuments
      )
    );
    setOpenModalRemovaImage(false);
    setReadyForDelete(false);
  };

  const defineNationality = (cpf, birthDate) => {
    if (cpf === '' && isMinor(handleFormatDate(birthDate))) {
      return 'B';
    }

    if (cpf && isCPF(cpf)) {
      return 'B';
    } else {
      return 'E';
    }
  };

  const openRemoveImage = () => {
    setOpenModalRemovaImage(true);
  };

  const textAreaRef = useRef(null);
  const handleCopyClipModal = (e) => {
    textAreaRef.current.select();
    document.execCommand('copy');
  };

  const getQueueType = (editAcionamento) =>
    formsType[editAcionamento?.queue] || null;

  const handleClose = () => {
    dispatch(alertActions.clear());
  };

  const formsType = {
    checkup: <EisteinClinicAndCareForm />,
    predicta: <EisteinClinicAndCareForm />,
    reab: <EisteinClinicAndCareForm />,
    clinica: <EisteinClinicAndCareForm />,
    HMVSC: <EisteinClinicAndCareForm />,
  };

  const scheduleForm = formValues(editAcionamento, values);

  const handleRemoveAttendance = () => {
    const data = Object.assign({}, getFormValues);

    if (data.encounterHealthOperator === 'Outros') {
      data.encounterHealthOperator = data.encounterHealthOperatorName;
    }

    setItemDelete({
      id: formValues(editAcionamento, values)._id,
      data,
    });

    setOpenDelete(true);
  };

  const handleCreatePushCall = async () => {
    setPushCallLoading(true);
    setPushCallTitle('Ligando');
    setPushCallMessage('Aguarde, estamos ligando para o paciente.');
    try {
      const pushCallInput = sessionIdState
        ? { sessionId: sessionIdState }
        : { document: documentState };

      await pushCall(pushCallInput);
    } catch (e) {
      if (e.response.data.message) {
        setPushCallTitle('Erro ao realizar ligação');
        setPushCallMessage(e.response.data.message);
        setPushCallLoading(true);
        return;
      }

      setPushCallLoading(false);
      setPushCallTitle('Erro ao realizar ligação');
      setPushCallMessage(
        'Houve um erro para concluir a sua ligação. Deseja tentar novamente?'
      );
    }
  };

  return productsTriggerQueue.includes(scheduleForm?.queue) ? (
    <EisteinClinicAndCareForm />
  ) : (
    <>
      {loading && <Loading />}
      <ModalAlertLink
        title={'Link de acesso'}
        copy={(e) => handleCopyClipModal(e)}
        close={() => handleCloseAlertLink()}
        sendEmail={() => sendEmail()}
        textAreaRef={textAreaRef}
      />

      <Alert
        open={open}
        type={type}
        message={message}
        onClose={() => handleClose()}
      />

      {openModalAlert && (
        <ModalAlert
          open={openModalAlert}
          type={typeModalAlert}
          message={messageModalAlert}
          close={() => {
            handleCloseNewModal();
          }}
        />
      )}
      <GlobalStyle />

      <Container>
        <ContainerPage>
          <ContainerForm>
            <Header>
              <CustomTitle
                width='99%'
                height='100px'
                className='rubik'
                title={
                  formValues(editAcionamento, values)?.name
                    ? 'Detalhes do Acionamento'
                    : 'Acionamento Manual'
                }
                pos='flex-start'
                level={1}
              ></CustomTitle>
              {formValues(editAcionamento, values)?.isVonage &&
                formValues(editAcionamento, values)?.isVonage && (
                  <ContainerButton>
                    <CustomButton
                      onClick={() => {
                        handleAttendance(
                          formValues(editAcionamento, values),
                          setInAttendance
                        );
                      }}
                      size={'large'}
                      typeButtom={'green'}
                    >
                      <IconButton src={Video} alt='videoconfêrencia' />{' '}
                      <span>Videoconfêrencia</span>
                    </CustomButton>
                  </ContainerButton>
                )}
            </Header>
            {/* <AsideLeft className={'AsideLeft'}></AsideLeft> */}
            <Main>
              <Formik
                initialValues={formValues(editAcionamento, values)}
                typeForm={typeForm}
                onSubmit={(values, actions, typeForm, resetForm) => {
                  handleSubmit(values, actions, typeForm, resetForm);
                }}
                validationSchema={generateSchema({
                  showExclusiveFields,
                  translateNationality,
                })}
              >
                {({
                  values,
                  resetForm,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  setFieldValue,
                  setFieldError,
                  setFieldTouched,
                  submitCount,
                  clear,
                }) => {
                  const hasError = (key) => submitCount > 0 && errors[key];
                  const handleChangeEncounterHealthOperator = (evt) => {
                    handleChange(evt);

                    if (evt?.target?.value !== 'Outros') {
                      setFieldValue('encounterHealthOperatorName', '');
                      setFieldTouched(
                        'encounterHealthOperatorName',
                        false,
                        true
                      );
                    }
                  };

                  return (
                    <Form
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') e.preventDefault();
                      }}
                    >
                      {isSubmitting && <ScrollToError />}
                      {setFormValues(values)}
                      <FormAdmContainer>
                        {showExclusiveFields && (
                          <>
                            <ContainerInputs>
                              <ContainerLabelInput>
                                <LabelInput>Operadora de saúde</LabelInput>
                                <SelectDiv>
                                  <GenericSelect
                                    height='large'
                                    id='encounterHealthOperator'
                                    placeholder='Selecione uma opção'
                                    value={values.encounterHealthOperator}
                                    onChange={
                                      handleChangeEncounterHealthOperator
                                    }
                                    data={healthOperatorOptions}
                                    name='encounterHealthOperator'
                                    onBlur={handleBlur}
                                    validation={{
                                      errors: errors.encounterHealthOperator,
                                      touched: hasError(
                                        'encounterHealthOperator'
                                      ),
                                    }}
                                    color='1px solid #ff2424'
                                    bgColor='#f1f4f6'
                                    opacity={1}
                                    textColor='#6b7786'
                                    onHover
                                  />
                                  {hasError('encounterHealthOperator') && (
                                    <ErrorInput>
                                      {errors.encounterHealthOperator}
                                    </ErrorInput>
                                  )}
                                </SelectDiv>
                              </ContainerLabelInput>
                              <ContainerLabelInput>
                                <FormInput
                                  labelTitle='Nome da operadora'
                                  placeholder='Informe o nome da operadora'
                                  type='text'
                                  id='encounterHealthOperatorName'
                                  name='encounterHealthOperatorName'
                                  value={values.encounterHealthOperatorName}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  validation={{
                                    errors: errors.encounterHealthOperatorName,
                                    touched: hasError(
                                      'encounterHealthOperatorName'
                                    ),
                                  }}
                                  color='1px solid  #ff2424'
                                  disabled={
                                    values.encounterHealthOperator !== 'Outros'
                                  }
                                />
                                {hasError('encounterHealthOperatorName') && (
                                  <ErrorInput>
                                    {errors.encounterHealthOperatorName}
                                  </ErrorInput>
                                )}
                              </ContainerLabelInput>
                            </ContainerInputs>
                            <ContainerInputs>
                              <ContainerLabelInput>
                                <FormInput
                                  labelTitle='Plano'
                                  placeholder='Informe o plano do paciente'
                                  type='text'
                                  id='encounterPlan'
                                  name='encounterPlan'
                                  value={values.encounterPlan}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  validation={{
                                    errors: errors.encounterPlan,
                                    touched: hasError('encounterPlan'),
                                  }}
                                  color='1px solid  #ff2424'
                                />
                                {hasError('encounterPlan') && (
                                  <ErrorInput>
                                    {errors.encounterPlan}
                                  </ErrorInput>
                                )}
                              </ContainerLabelInput>
                              <ContainerLabelInput height='90px'>
                                <LabelInput>
                                  Paciente elegível a empresa?
                                </LabelInput>
                                <S.RadioRow>
                                  <CustomRadioGroup
                                    name='isPatientEligible'
                                    id='isPatientEligible'
                                    data={isPatientEligibleOptions}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values?.isPatientEligible}
                                  />
                                  {hasError('isPatientEligible') && (
                                    <ErrorInput>
                                      {errors.isPatientEligible}
                                    </ErrorInput>
                                  )}
                                </S.RadioRow>
                              </ContainerLabelInput>
                            </ContainerInputs>
                            <ContainerInputs>
                              <ContainerLabelInput>
                                <PurchaseCodeField
                                  labelTitle='Id do pedido'
                                  placeholder='Informe o id do pedido'
                                  type='text'
                                  id='purchaseCode'
                                  name='purchaseCode'
                                  value={values?.purchaseCode}
                                  onChange={handleChange}
                                  handleBlur={handleBlur}
                                  validation={{
                                    errors: errors.purchaseCode,
                                    touched: hasError('purchaseCode'),
                                  }}
                                  disabled={values.isPatientEligible !== 'não'}
                                />
                                {hasError('purchaseCode') && (
                                  <ErrorInput>{errors.purchaseCode}</ErrorInput>
                                )}
                              </ContainerLabelInput>
                              <ContainerLabelInput />
                            </ContainerInputs>
                          </>
                        )}
                        <ContainerInputs>
                          <ContainerLabelInput>
                            <LabelInput>Convênio</LabelInput>
                            {values?.edit ? (
                              <>
                                <SelectDiv>
                                  <FormInput
                                    height={'large'}
                                    id='convenio'
                                    value={values.convenioDescricao}
                                    name='convenio'
                                    onBlur={handleBlur}
                                    disabled={true}
                                  />
                                </SelectDiv>
                              </>
                            ) : (
                              <SelectDiv>
                                <GenericSelect
                                  height={'large'}
                                  id='convenio'
                                  placeholder='Selecione'
                                  value={
                                    values?.edit
                                      ? values.convenioDescricao
                                      : values.convenio
                                  }
                                  onChange={handleChange}
                                  data={objConvenio(convenios)}
                                  name='convenio'
                                  onBlur={handleBlur}
                                  validation={{
                                    errors: errors.convenio,
                                    touched: touched.convenio,
                                  }}
                                  color={'1px solid #ff2424'}
                                  bgColor={'#f1f4f6'}
                                  onHover
                                  opacity={1}
                                  textColor={'#6b7786'}
                                />
                                {errors.convenio && touched.convenio && (
                                  <ErrorInput>{errors.convenio}</ErrorInput>
                                )}
                              </SelectDiv>
                            )}
                          </ContainerLabelInput>
                          <ContainerLabelInput>
                            <FormInput
                              disabled={returnReception}
                              labelTitle={'Carteirinha'}
                              placeholder={'Carteirinha'}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              mask
                              type={'text'}
                              name={'numberCard'}
                              id={'numberCard'}
                              validation={{
                                errors: errors.numberCard,
                                touched: touched.numberCard,
                              }}
                              color={'1px solid #ff2424'}
                              value={values.numberCard}
                            />
                            {errors.numberCard && touched.numberCard && (
                              <ErrorInput>{errors.numberCard}</ErrorInput>
                            )}
                          </ContainerLabelInput>
                        </ContainerInputs>
                        <ContainerInputs>
                          <ContainerLabelInput height='130px'>
                            <FormInput
                              disabled={returnReception}
                              labelTitle={'Nome'}
                              placeholder={'No mínimo 2 caracteres'}
                              type={'text'}
                              id={'name'}
                              name={'name'}
                              value={values.name}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              mask=''
                              validation={{
                                errors: errors.name,
                                touched: touched.name,
                              }}
                              color={'1px solid  #ff2424'}
                            />
                            {errors.name && touched.name && (
                              <ErrorInput>{errors.name}</ErrorInput>
                            )}
                          </ContainerLabelInput>
                          <ContainerLabelInput height='130px'>
                            <FormInput
                              disabled={returnReception}
                              labelTitle={'Sobrenome'}
                              placeholder={'No mínimo 2 caracteres'}
                              type={'text'}
                              id={'lastName'}
                              name={'lastName'}
                              value={values.lastName}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              validation={{
                                errors: errors.lastName,
                                touched: touched.lastName,
                              }}
                              color={'1px solid  #ff2424'}
                            />
                            {errors.lastName && touched.lastName && (
                              <ErrorInput>{errors.lastName}</ErrorInput>
                            )}
                          </ContainerLabelInput>
                        </ContainerInputs>
                        <ContainerInputs>
                          <ContainerLabelInput
                            marginBottom={'-80px'}
                            marginTop={'-30px'}
                          >
                            <CheckBox
                              textLabel={'Nome Social'}
                              type={'checkbox'}
                              name={'checkSocialName'}
                              checked={values.checkSocialName}
                            />
                          </ContainerLabelInput>
                        </ContainerInputs>
                        {values.checkSocialName && (
                          <ContainerLabelInput
                            width={'97%'}
                            marginBottom={'-40px'}
                            marginTop={'-10px'}
                          >
                            <FormInput
                              disabled={returnReception}
                              labelTitle={''}
                              name={'socialName'}
                              id={'socialName'}
                              type={'text'}
                              value={values.socialName}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              validation={{
                                errors: errors.socialName,
                                touched: touched.socialName,
                              }}
                              color={'1px solid #ff2424'}
                            />

                            {errors.socialName && touched.socialName && (
                              <ErrorInput>{errors.socialName}</ErrorInput>
                            )}
                          </ContainerLabelInput>
                        )}
                        <ContainerInputs>
                          <ContainerLabelInput marginTop='15px'>
                            <LabelInput>Nacionalidade</LabelInput>
                            <>
                              <SelectDiv>
                                <GenericSelect
                                  height={'large'}
                                  value={values.nationality}
                                  disabled={values?.edit}
                                  onChange={handleChange}
                                  data={Nacionality}
                                  placeholder={'Selecione'}
                                  id='nationality'
                                  name='nationality'
                                  onBlur={handleBlur}
                                  bgColor='#f1f4f6'
                                  validation={{
                                    errors: errors.nationality,
                                    touched: touched.nationality,
                                  }}
                                  color={'1px solid #ff2424'}
                                  onHover
                                  opacity={1}
                                  textColor={'#6b7786'}
                                />
                              </SelectDiv>
                              {errors.nationality && touched.nationality && (
                                <ErrorInput>{errors.nationality}</ErrorInput>
                              )}
                            </>
                          </ContainerLabelInput>
                          <ContainerLabelInput marginTop='15px'>
                            <LabelInput>Documento</LabelInput>
                            <InputMask
                              placeholder={
                                !values?.edit
                                  ? setPlaceHolderDocCreate(values.nationality)
                                  : setPlaceHolderDocEdit(
                                      values.document,
                                      values.nationality
                                    )
                              }
                              name={'document'}
                              id={'document'}
                              type={'text'}
                              value={values.document}
                              onChange={handleChange}
                              disabled={values?.edit}
                              onBlur={handleBlur}
                              mask={
                                !values?.edit
                                  ? setMask(
                                      maskDocumentCreate(values.nationality)
                                    )
                                  : setMask(maskDocumentEdit(values.document))
                              }
                              maskChar=''
                              fullWidth
                              validation={{
                                errors: errors.document,
                                touched: touched.document,
                              }}
                              color={'1px solid #ff2424'}
                            />
                            {errors.document && touched.document && (
                              <ErrorInput>{errors.document}</ErrorInput>
                            )}
                          </ContainerLabelInput>
                        </ContainerInputs>
                        <ContainerInputs>
                          <ContainerLabelInput>
                            <LabelInput>Sexo</LabelInput>
                            <SelectDiv>
                              <GenericSelect
                                disabled={returnReception}
                                height={'large'}
                                value={
                                  values?.gender === 'Masculino'
                                    ? 'M'
                                    : values?.gender === 'Feminino'
                                    ? 'F'
                                    : values?.gender === 'MASCULINO'
                                    ? 'M'
                                    : values?.gender === 'FEMININO'
                                    ? 'F'
                                    : values.gender
                                }
                                onChange={handleChange}
                                data={Sexo}
                                name='gender'
                                id='gender'
                                placeholder='Selecione'
                                onBlur={handleBlur}
                                bgColor='#f1f4f6'
                                validation={{
                                  errors: errors.gender,
                                  touched: touched.gender,
                                }}
                                color={'1px solid #ff2424'}
                                onHover
                                opacity={1}
                                textColor={'#6b7786'}
                              />
                              <ErrorInput>
                                {values.edit ? errors.gender : touched.gender}
                              </ErrorInput>
                            </SelectDiv>
                          </ContainerLabelInput>
                          <ContainerLabelInput>
                            <LabelInput>Data de nascimento</LabelInput>
                            <InputMask
                              label={'Data de nascimento'}
                              placeholder={'DD/MM/AAAA'}
                              disabled={values?.edit}
                              type={'text'}
                              name={'birthDate'}
                              id={'birthDate'}
                              value={
                                values?.edit
                                  ? handleFormatDate(values.birthDate)
                                  : values.birthDate
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              mask={setMask('nascimento')}
                              maskChar=''
                              fullWidth
                              validation={{
                                errors: errors.birthDate,
                                touched: touched.birthDate,
                              }}
                              color={'1px solid  #ff2424'}
                            />
                            {errors.birthDate && touched.birthDate && (
                              <ErrorInput>{errors.birthDate}</ErrorInput>
                            )}
                          </ContainerLabelInput>
                        </ContainerInputs>
                        <div>
                          <ContainerInputs>
                            <ContainerLabelInput marginBottom={space(values)}>
                              <SelectGroup
                                group='1'
                                formikValues={values}
                                setFieldValue={setFieldValue}
                                onBlur={handleBlur}
                                errors={errors}
                                setFieldError={setFieldError}
                                touched={touched}
                                isEdit={
                                  values?.edit === undefined ? false : true
                                }
                                handleChange={handleChange}
                              />
                            </ContainerLabelInput>
                            <ContainerLabelInput marginBottom={space(values)}>
                              <SelectGroup
                                group='2'
                                formikValues={values}
                                setFieldValue={setFieldValue}
                                onBlur={handleBlur}
                                errors={errors}
                                setFieldError={setFieldError}
                                touched={touched}
                                handleChange={handleChange}
                              />
                            </ContainerLabelInput>
                          </ContainerInputs>
                        </div>
                        <ContainerInputs>
                          <ContainerLabelInput marginTop='20px'>
                            <LabelInput>Telefone</LabelInput>
                            <InputMask
                              disabled={returnReception}
                              labelTitle={'Telefone'}
                              placeholder='(DDD) + Telefone'
                              type={'text'}
                              name={'phone'}
                              id={'phone'}
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              mask={setMask('phone')}
                              maskChar=' '
                              fullWidth
                              validation={{
                                errors: errors.phone,
                                touched: touched.phone,
                              }}
                              color={'1px solid  #ff2424'}
                            />
                            {errors.phone && touched.phone && (
                              <ErrorInput>{errors.phone}</ErrorInput>
                            )}
                          </ContainerLabelInput>
                          <ContainerLabelInput marginTop='20px'>
                            <LabelInput>Telefone</LabelInput>
                            <InputMask
                              disabled={returnReception}
                              labelTitle={'Telefone'}
                              placeholder={'(DDD) + Telefone'}
                              type={'text'}
                              name={'phone2'}
                              id={'phone2'}
                              value={values.phone2}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              mask={setMask('phone')}
                              maskChar=' '
                              fullWidth
                            />
                          </ContainerLabelInput>
                        </ContainerInputs>
                        <ContainerInputs>
                          <ContainerLabelInput>
                            <FormInput
                              disabled={returnReception}
                              labelTitle={'E-mail'}
                              placeholder={'E-mail'}
                              type={'email'}
                              name={'email'}
                              id={'email'}
                              value={values.email}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              validation={{
                                errors: errors.email,
                                touched: touched.email,
                              }}
                              color={'1px solid #ff2424'}
                            />
                            {errors.email && touched.email && (
                              <ErrorInput>{errors.email}</ErrorInput>
                            )}
                          </ContainerLabelInput>
                          <ContainerLabelInput>
                            <FormInput
                              disabled={returnReception}
                              labelTitle={'E-mail secundário'}
                              placeholder={'E-mail'}
                              type={'email2'}
                              id={'email2'}
                              value={values.email2}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </ContainerLabelInput>
                        </ContainerInputs>
                        <ContainerInputs>
                          <ContainerLabelInput>
                            <FormInput
                              disable={returnReception}
                              labelTitle={'Canal de acesso'}
                              placeholder={'Canal de acesso'}
                              type={'deviceData'}
                              id={'deciceData'}
                              value={
                                values.deviceData
                                  ? values.deviceData.deviceType
                                  : 'Não recebido'
                              }
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              disabled
                            />
                          </ContainerLabelInput>
                          <ContainerLabelInput>
                            <FormInput
                              disabled={returnReception}
                              labelTitle={'Identificador Parceiro'}
                              placeholder={'Identificador parceiro'}
                              type={'partnerID'}
                              id={'partnerID'}
                              value={values.partnerID}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          </ContainerLabelInput>
                        </ContainerInputs>
                        {values?.edit && (
                          <>
                            <ContainerInputs>
                              <ContainerLabelInput>
                                <CheckBox
                                  textLabel={'Comportamento Disruptivo'}
                                  type={'checkbox'}
                                  name={'checkDisruptiveBehavior'}
                                  disabled={
                                    values.checkUrgencyAttendance ? true : false
                                  }
                                  checked={values.checkDisruptiveBehavior}
                                />
                              </ContainerLabelInput>
                              {/* <ContainerLabelInput>
                              <CheckBox
                                textLabel={'Atendimento de Emergência'}
                                type={'checkbox'}
                                name={'checkUrgencyAttendance'}
                                disabled={
                                  values.checkDisruptiveBehavior ? true : false
                                }
                              />
                            </ContainerLabelInput> */}
                            </ContainerInputs>
                            {values.checkDisruptiveBehavior && (
                              <ContainerLabelInput
                                marginTop={'-80px'}
                                width={'98%'}
                              >
                                <FormInput
                                  value={values.disruptiveBehavior}
                                  labelTitle={''}
                                  type={'disruptiveBehavior'}
                                  id={'disruptiveBehavior'}
                                  handleChange={handleChange}
                                />
                              </ContainerLabelInput>
                            )}
                            {values.checkUrgencyAttendance && (
                              <ContainerLabelInput
                                marginTop={'-80px'}
                                width={'98%'}
                              >
                                <FormInput
                                  value={values.urgencyAttendance}
                                  labelTitle={''}
                                  id={'urgencyAttendance'}
                                  handleChange={handleChange}
                                />
                              </ContainerLabelInput>
                            )}
                          </>
                        )}

                        <Line />
                        {values?.edit ? (
                          <ButtonsForm>
                            <ContainerButton>
                              <CustomButton
                                onClick={handleBack}
                                type={'button'}
                                fontSize='10px'
                                size={'medium'}
                                typeButtom={'dangerHighlight'}
                                border='solid 1px #ccc'
                              >
                                Cancelar
                              </CustomButton>
                            </ContainerButton>
                            <ContainerButton>
                              <CustomButton
                                disable={
                                  values?.isVonage ? inAttendance : false
                                }
                                id={1}
                                fontSize='10px'
                                size={'medium'}
                                typeButtom={'primary'}
                                border='solid 1px #ccc'
                                type={'submit'}
                              >
                                Confirmar
                              </CustomButton>
                            </ContainerButton>
                          </ButtonsForm>
                        ) : (
                          <ButtonsForm>
                            <ContainerButton>
                              <CustomButton
                                onClick={handleBack}
                                type={'button'}
                                size={'medium'}
                                typeButtom={'dangerHighlight'}
                                border='solid 1px #ccc'
                              >
                                Cancelar
                              </CustomButton>
                            </ContainerButton>
                            <ContainerButton>
                              <CustomButton
                                onClick={() => {
                                  resetForm();
                                }}
                                size={'medium'}
                                typeButtom={'primaryHighlight'}
                                border='solid 1px #ccc'
                                type={'button'}
                              >
                                Limpar
                              </CustomButton>
                            </ContainerButton>

                            <ContainerButton>
                              <CustomButton
                                size={'medium'}
                                typeButtom={'primary'}
                                border='solid 1px #ccc'
                                type={'submit'}
                              >
                                Cadastrar
                              </CustomButton>
                            </ContainerButton>
                          </ButtonsForm>
                        )}
                      </FormAdmContainer>
                    </Form>
                  );
                }}
              </Formik>
            </Main>
            {/* <AsideRight /> */}
          </ContainerForm>
          {/* </GridLayout> */}
          {formValues(editAcionamento, values).edit && (
            <ContainerAccordion>
              <div>
                <Accordion
                  accordionSubtitle={'Vizualizar'}
                  accordionTitle={'Documentação'}
                >
                  {signUpDocuments?.length ? (
                    <ContainerBodyAccordion>
                      {signUpDocuments?.map((el, index) => {
                        return (
                          <>
                            {loadingDocuments ? (
                              <ContainerLoaderFastActions>
                                <Loader background={'#007ACC'} />
                              </ContainerLoaderFastActions>
                            ) : (
                              <ImageDocument
                                rel={'preload'}
                                src={el.urls}
                                alt='documento'
                                key={index}
                                onClick={() => handleOpenDocument(el.urls)}
                              />
                            )}
                          </>
                        );
                      })}
                    </ContainerBodyAccordion>
                  ) : (
                    <ContainerBodyAccordion>
                      <p>Paciente sem documento</p>
                    </ContainerBodyAccordion>
                  )}
                </Accordion>
              </div>
              <Accordion
                accordionSubtitle={'Ações'}
                accordionTitle={'Rápidas'}
                loadFunction={false}
              >
                <ContainerBodyAccordion>
                  <ContainerListActions>
                    {formValues(editAcionamento, values)?.isVonage &&
                      readyForDelete && (
                        <ItemList
                          onClick={() => {
                            openRemoveImage(values);
                          }}
                        >
                          <>
                            <AccordionIcon>
                              <GrDocumentMissing />
                            </AccordionIcon>
                            <ItemDescription>
                              Excluir documentação
                            </ItemDescription>
                          </>
                        </ItemList>
                      )}
                    <ItemList
                      onClick={() => {
                        setItemFoward({
                          data: formValues(editAcionamento, values),
                        });
                        setOpenModalSupport(true);
                      }}
                    >
                      <AccordionIcon>
                        <AiOutlineSend />
                      </AccordionIcon>
                      <ItemDescription>Encaminhar para suporte</ItemDescription>
                    </ItemList>
                    <ItemList onClick={handleRemoveAttendance}>
                      <AccordionIcon>
                        <FaTrashAlt />
                      </AccordionIcon>

                      <ItemDescription>Remover</ItemDescription>
                    </ItemList>

                    {formValues(editAcionamento, values)
                      .urlAttendanceVonage && (
                      <>
                        <ItemList onClick={handleOpenReenvio}>
                          <AccordionIcon>
                            <AiOutlineSend />
                          </AccordionIcon>
                          <ItemDescription>Reenvio de link</ItemDescription>
                        </ItemList>
                      </>
                    )}
                  </ContainerListActions>
                </ContainerBodyAccordion>
              </Accordion>
              {/* {process.env.REACT_APP_EXECUTE_PUSH_CALL === 'true' && */}
              {false && encounter?.pushCalls?.length > 0 && (
                <PushCallsCard>
                  <PushCallsCardTitle
                    color={chipColor(encounter.pushCalls?.length || 0).color}
                    backgroundColor={
                      chipColor(encounter.pushCalls?.length || 0)
                        .backgroundColor
                    }
                  >
                    <div className='chip'>
                      <FiPhoneIcon className='icon' />
                      <div>{encounter.pushCalls?.length}</div>
                    </div>
                    <h3>Tentativas de Contato</h3>
                  </PushCallsCardTitle>
                  {encounter.pushCalls?.map((item) => (
                    <PushCallsCardItem key={item.callUUID}>
                      <div className='time'>
                        {formatPushCallTime(item.createdAt)}
                      </div>
                      <div className='username'>{item.caller}</div>
                    </PushCallsCardItem>
                  ))}
                </PushCallsCard>
              )}
            </ContainerAccordion>
          )}
        </ContainerPage>
      </Container>

      <ModalDeleteDocs
        open={openModalRemoveImage}
        handleClose={handleRemoveCancel}
        handleConfirm={handleRemoveConfirm}
        title={'Remover documentos'}
        typeButtomConfirm={'dangerHighlight'}
        typeButtomCancel={'primaryHighlight'}
        labelCancel={'Cancelar'}
        labelOk={'Confimar'}
        withActions
        withClose
      >
        Tem certeza que deseja remover os documentos anexados?
      </ModalDeleteDocs>

      <ModalDialog
        open={openModalSupport}
        handleClose={handleCloseModalSupport}
        handleConfirm={handleConfirmSupport}
        title={'Encaminhamento para o suporte'}
        typeButtomConfirm={'dangerHighlight'}
        typeButtomCancel={'primaryHighlight'}
        labelCancel={'Cancelar'}
        labelOk={'Confimar'}
        withActions
        withClose
      >
        <ContainerSelectModal>
          <SelectTitle>Motivo</SelectTitle>
          <div>
            <GenericSelect
              placeholder='Selecione'
              height={'medium'}
              value={reasonSupport}
              onChange={handleChangeReasonSupport}
              data={supportReason}
            />
          </div>

          {suportError && <ValueCheck>Este campo é obrigatório</ValueCheck>}
        </ContainerSelectModal>
      </ModalDialog>

      <ModalDialog
        open={openReenvio}
        handleClose={handleCloseReenvio}
        handleConfirm={ResendEmail}
        handleCopy={(e) => handleCopyReenvio(e)}
        title={'Reenvio de link de acesso'}
        withActions
        withClose
        labelCancel={'Cancelar'}
        labelCopy={'Copiar'}
        labelOk={'Enviar e-mail'}
        typeButtomConfirm={'primaryHighlight'}
        typeButtomCopy={'primary'}
        typeButtomCancel={'primary'}
      >
        <ContainerSelectModal>
          <div>
            <TextAreaModal
              ref={textAreaCopy}
              value={reenvioLink}
            ></TextAreaModal>
          </div>
        </ContainerSelectModal>
      </ModalDialog>
      <ModalDialog
        open={openDelete}
        handleClose={handleCloseDelete}
        handleConfirm={handleConfirmDelete}
        title={'Deseja remover o acionamento ?'}
        withActions
        withClose
        labelCancel={'Cancelar'}
        labelOk={'Remover'}
        typeButtomConfirm={'primaryHighlight'}
        typeButtomCancel={'danger'}
      >
        <ContainerSelectModal>
          <SelectTitle>Motivo</SelectTitle>
          <div>
            <GenericSelect
              height={'medium'}
              value={reasonRemove}
              placeholder='Selecione'
              onChange={handleReason}
              data={removeReason}
            />
          </div>
          {deleteError && <ValueCheck>Este campo é obrigatório</ValueCheck>}
        </ContainerSelectModal>
      </ModalDialog>
      <ModalDialog
        open={openImageDocument}
        handleClose={handleCloseDocument}
        withClose
        width={'900px'}
        height={'600px'}
        imgContentFlg={true}
      >
        <ImageDocumentZoom src={imageSrc} alt='documento' />
      </ModalDialog>

      <ModalDialog
        open={patientNetworkError}
        handleConfirm={() => setPatientNetworkError(false)}
        title={'Paciente desconectado'}
        typeButtomConfirm={'dangerHighlight'}
        labelOk={'OK'}
        withActions
        height='180px'
      />

      <Modal
        open={pushCallModal}
        withActions={!pushCallLoading}
        title={pushCallTitle}
        text={pushCallMessage}
        withClose
        loading={loading}
        loader={<Loader background={'#007ACC'} />}
        handleCancel={() => setPushCallModal(false)}
        handleConfirm={handleCreatePushCall}
        labelOk={'Sim'}
        labelCancel={'Não'}
        buttomSize='large'
      />

      <Alert
        open={pushCallError}
        type='error'
        message='Não foi possível carregar as Tentativas de Contato'
        onClose={() => setPushCallError(false)}
      />
    </>
  );
}
