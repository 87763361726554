import { Container, Grid } from '@material-ui/core';
import { styled as styledMui, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { history } from '../helpers';
import banner_cad from '../img/Banner.png';
import logoCentral from '../img/logo_central.svg';
import './App.css';

import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {
  acionamentoActionsVonage,
  actionNotification,
  alertActions,
  filaSuporte,
  passwordActions,
  userActions,
} from '../actions';
import ModalComunicados from './Comunicados/ModalComunicados';
import Validations from './Comunicados/validations/validations';
import MenuLogado from './MenuLogado';
const io = require('socket.io-client');

const loggedUser = localStorage.getItem('user');

const getRouteRedirect = (user) => {
  let profile = JSON.parse(user);
  profile =
    profile?.user?.access[0]?.profile?.description.toLowerCase() ??
    profile?.user?.id_perfil?.descricao.toLowerCase();
  const profileType = profile;

  const views = {
    suporte: '/adm/scales',
    gestor: '/adm/scales',
    administrativo: '/adm/scales/me',
    'suporte técnico': '/adm/scales/me',
    'Suporte Técnico': '/adm/scales',
  };

  const routeRedirect = views[profileType] || '';

  return routeRedirect;
};

const collectionPush = {
  acionamento: '/adm',
  historico: '/historico',
  historicoSuporte: '/historico/suporte',
  atividades: '/adm/atividades',
  cancelamentosAtivos: '/canc/ativos',
  cancelamentosFinalizados: '/canc/finalizados',
  teletriagem: '/teletriagem',
  //vonage: "/admVonage",
  desvinculo: '/adm/desvinculo',
  suporte: '/suporte',
  portalConecta: '/portalConecta',
  comunicados: '/adm/comunicados',
  centralRelacionamento: '/adm/search',
  responsalvelFinanceiro: '/adm/responsavelfinanceiro',
  filatelepsicologia: '/adm/fila/telepsicologia',
  ...(loggedUser
    ? {
        escalas: getRouteRedirect(loggedUser),
      }
    : {}),
};

var intervalNotifications = null;
var intervalAudios = null;

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -1,
    top: 14,
    padding: '0 4px',
    height: '20px',
    width: '15px',
  },
}))(Badge);

const styles = (theme) => ({
  header: {
    background: `url(${banner_cad})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    height: '99vh',
    '@media only screen and (max-width: 900px)': {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: '100%',
    },
  },
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
    height: '',
    fontFamily: 'Poppins',
    paddingTop: '15px',
  },
  button: {
    background: 'none',
    border: '0',
    '@media only screen and (max-width: 900px)': {
      display: 'none',
    },
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
  },
  text_banner: {
    fontFamily: 'Inter',
    fontSize: '60px',
    float: 'right',
    padding: '70px 30px 30px 30px',
    color: 'white',
    marginRight: '20%',
    fontWeight: '200',
    lineHeight: '1.2',
    textShadow: '#194082 0.01em 0.01em 0.2em',
    '@media only screen and (max-width: 900px)': {
      fontSize: '40px',
      float: 'right',
      padding: '60px 5% 30px 0px',
      color: 'white',
      marginRight: '0',
      fontWeight: '200',
      lineHeight: '1.2',
      textShadow: '#194082 0.01em 0.01em 0.2em',
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },

  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.6,
    transition: theme.transitions.create('opacity'),
  },

  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 1}px ${
      theme.spacing.unit + 1
    }px`,
    fontSize: '10px',
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
  card: {
    maxWidth: 345,
  },
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: 'cover',
  },
  menubar: {
    width: '100%',
  },
  showNone: {
    '@media only screen and (max-width: 900px)': {
      display: 'none',
    },
  },
  menuMobile: {
    display: 'none',
    float: 'right',
    margin: '0 10px',
    '@media only screen and (max-width: 900px)': {
      display: 'block',
      padding: '0 0 20px 0',
    },
  },
  padding_top: {
    '@media only screen and (max-width: 900px)': {
      width: '300px',
      padding: '40px 0 20px 0',
    },
  },
  headerTitle: {
    fontFamily: 'Inter',
  },
});

const Content = styled.div`
  background: url(${banner_cad});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 99vh;
  @media only screen and (max-width: 900px) {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }
`;

const GridContainer = styledMui(Grid)({
  '& .MuiGrid-root': {
    height: '70px',
  },
});

const Header = styled.header`
  height: 70px;
  margin: auto;
  max-width: 1680px;
  padding: 0 30px;
  width: 100%;
  @media (max-width: 1370px) {
    max-width: 1300px;
  }
  @media (max-width: 960px) {
    height: 100px;
  }
`;

const Title = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  font-family: 'Inter', sans-serif;
  height: 70px;
  justify-content: center;
  @media (max-width: 960px) {
    display: none;
  }
  & h1 {
    font-size: 22px;
  }
`;

const TitleSm = styled.div`
  align-items: center;
  color: #fff;
  display: none;
  font-family: 'Inter', sans-serif;
  height: 30px;
  justify-content: center;
  @media (max-width: 960px) {
    display: flex;
  }
  & h1 {
    font-size: 22px;
  }
`;

const LogoCr = styled(Link)`
  align-items: center;
  display: flex;
  height: 70px;
  & img {
    filter: brightness(0) invert(1);
  }
`;

const Profile = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  height: 70px;
  justify-content: flex-end;
`;

class Menu extends React.Component {
  constructor() {
    super();
    /*
    this.socket = io.connect(process.env.REACT_APP_API_SOCKET_IO_CR);
    */
    this.state = {
      totalAttendance: 0,
      menuOpen: false,
      notificationOpen: false,
      arr: [],
      cont: 0,
    };
    this.logout = this.logout.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.openNotification = this.openNotification.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
  }
  state = {
    open: false,
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleHome() {
    history.push('/adm');
  }

  handleHomeLogo() {
    const user = JSON.parse(window.localStorage.user);
    if (user?.user?.id_perfil?.descricao != 'Empresa - Administrativo' || user?.user?.id_perfil?.descricao != 'Empresa - Administrativo 2')
      return history.push('/adm');
    return window.location.reload();
  }

  logout() {
    this.props.dispatch(userActions.logout());
    this.props.dispatch(alertActions.clear());
    localStorage.removeItem('user');
    history.push('/login');
  }

  handleChangePassword() {
    this.props.dispatch(passwordActions.changePassword());
  }

  openNotification() {
    this.setState({
      notificationOpen: true,
    });
  }
  closeNotification() {
    this.setState({
      notificationOpen: false,
    });
  }

  async notificationsUpdates(userLogin, state) {
    return await actionNotification
      .NotificationsWithoutRedux(userLogin)
      .then(async (res) => {
        const user = JSON.parse(window.localStorage.user);
        const perfil = user?.user?.id_perfil?.descricao;
        let notifyIds = await actionNotification.getIdNotificationsWithoutRedux(
          user
        );
        let resultValidations = await Validations(res.docs, notifyIds, perfil);

        //let cont = res.docs ? res.docs.length : 0

        state.setState({
          arr: resultValidations,
          cont: resultValidations.docs.length,
        });
      });
  }

  async bonkAudio() {
    return await acionamentoActionsVonage.getCount();
  }

  async bellSuportAudio() {
    return await filaSuporte.getCount();
  }

  componentWillMount() {
    const { userLogin } = this.props;

    collectionPush.escalas = getRouteRedirect(window.localStorage.user);

    (async () =>
      await this.notificationsUpdates(userLogin, this).catch((err) => {
        console.log(err);
      }))();
    /*
    this.socket.on('changeDataComunicados', async (payload) => {
      await this.notificationsUpdates(userLogin, this).catch((err) => {
        console.log(err);
      });
    });
    */
    //this.socket.on('')
    //intervalNotifications = setInterval(
    //  async () =>
    //    await this.notificationsUpdates(userLogin, this).catch((err) => {
    //      console.log(err);
    //    }),
    //  10000
    //);

    //intervalAudios = setInterval(async () => {
    //  try {
    //    let resSup = await this.bellSuportAudio();
    //    let res = await this.bonkAudio();
    //
    //    let audio = new Audio(bonk);
    //    let audioSup = new Audio(bellSuport);
    //
    //    if (
    //      parseInt(window.localStorage.totalAttendance) < res.totalAttendance
    //    ) {
    //      audio.loop = false;
    //      if (window.localStorage.playalert == 'true') {
    //      } //audio.play();
    //    }
    //
    //    if (
    //      parseInt(window.localStorage.totalAttendancesupport) <
    //      resSup.totalAttendance
    //    ) {
    //      audioSup.loop = false;
    //      if (window.localStorage.playalertsupport == 'true') {
    //      } //audioSup.play();
    //    }
    //
    //    localStorage.setItem('totalAttendancesupport', resSup.totalAttendance);
    //    localStorage.setItem('totalAttendance', res.totalAttendance);
    //  } catch (err) {
    //    console.log(err);
    //  }
    //}, 1000);
  }

  componentWillUnmount() {
    //this.socket.close();
    //clearInterval(intervalNotifications);
    //clearInterval(intervalAudios);
  }

  async componentWillReceiveProps(props) {
    const { userLogin } = this.props;
    //const result = await Validations(props.getNotifications, props.getIdNotifications, perfil)
  }

  handlePush(string) {
    history.push(string);
  }

  handleStructMenu(array) {
    let data = [];
    const objects = [
      {
        id: 1,
        url: '#',
        title: 'Trocar senha',
        width: '10%',
        click: this.handleChangePassword,
        height: '10px',
      },
      {
        id: '#',
        url: '#',
        title: 'Sair',
        width: '10%',
        click: this.logout,
        height: '10px',
      },
    ];

    const menu = array.map((element) => {
      data.push(this.handleMenu(element));
    });

    return [...data, ...objects];
  }

  handleMenu(title) {
    const push = {
      Acionamentos: () => {
        this.handlePush(collectionPush.acionamento);
      },
      'Cancelamentos - B2C': () => {
        this.handlePush(collectionPush.cancelamentosAtivos);
      },
      'Vínculo Claro-Empresas': () => {
        this.handlePush(collectionPush.desvinculo);
      },
      'Atividades de registro': () => {
        this.handlePush(collectionPush.atividades);
      },
      Histórico: () => {
        this.handlePush(collectionPush.historico);
      },
      'Histórico de Suporte': () => {
        this.handlePush(collectionPush.historicoSuporte);
      },
      'Portal Conecta': () => {
        this.handlePush(collectionPush.portalConecta);
      },
      Comunicados: () => {
        this.handlePush(collectionPush.comunicados);
      },
      'Central de Relacionamento': () => {
        this.handlePush(collectionPush.centralRelacionamento);
      },
      'Responsável Financeiro': () => {
        this.handlePush(collectionPush.responsalvelFinanceiro);
      },
      'Escalas/Pausas': () => {
        this.handlePush(collectionPush.escalas);
      },
    };

    const menuItem = {
      id: '#',
      url: '#',
      title: title,
      width: '10%',
      click: push[title],
      height: '10px',
    };
    return menuItem;
  }
  handlerSearch() {
    history.push('/adm/search');
  }

  handleProfileRoutes(location) {
    return this.handleStructMenu([
      'Acionamentos',
      'Histórico',
      'Central de Relacionamento',
      'Responsável Financeiro',
      'Portal Conecta',
      'Histórico de Suporte',
      'Cancelamentos - B2C',
      'Escalas/Pausas',
    ]);
  }

  handleProfileRoutesAdm(location) {
    return this.handleStructMenu([
      'Acionamentos',
      'Histórico',
      'Central de Relacionamento',
      'Escalas/Pausas',
    ]);
  }
  handleProfileRoutesAssistent(location) {
    return this.handleStructMenu([
      'Acionamentos',
      'Histórico',
      'Central de Relacionamento',
      'Responsável Financeiro',
    ]);
  }

  /*
    handleProfileRoutesAssistent(location) {
    const locations = {
      '/historico': this.handleStructMenu(['Acionamentos','Acionamentos Vonage','Central de Relacionamento','Responsável Financeiro','Fila Telepsicologia']),
      '/adm': this.handleStructMenu(['Acionamentos Vonage','Histórico','Central de Relacionamento','Responsável Financeiro','Fila Telepsicologia']),
      '/adm/acionamento': this.handleStructMenu(['Histórico','Central de Relacionamento','Responsável Financeiro','Fila Telepsicologia']),
      '/adm/responsavelfinanceiro': this.handleStructMenu(['Histórico', 'Central de Relacionamento','Acionamentos','Acionamentos Vonage','Fila Telepsicologia']),
      '/adm/search': this.handleStructMenu(['Acionamentos','Acionamentos Vonage', 'Histórico','Responsável Financeiro','Fila Telepsicologia']),
      '/adm/fila/telepsicologia': this.handleStructMenu(['Acionamentos','Acionamentos Vonage','Central de Relacionamento','Responsável Financeiro','Fila Telepsicologia','Histórico'])
    }
    return locations[location]
  }
  */
  handleProfileRoutesMedical(location) {
    const locations = {
      '/adm/agenda/telepsicologia': this.handleStructMenu([]),
    };
    return locations[location];
  }

  handleProfileRoutesSuporte(location) {
    return this.handleStructMenu([
      'Acionamentos',
      'Histórico',
      'Central de Relacionamento',
      'Portal Conecta',
      'Responsável Financeiro',
      'Cancelamentos - B2C',
      'Histórico de Suporte',
      'Escalas/Pausas',
    ]);
  }
  handleDefaultProfileRoutes() {
    return this.handleStructMenu([]);
  }

  handleMenuButtonsPerfilSegregation(perfil, location) {
    const perfils = {
      Administrativo: this.handleProfileRoutesAdm(location),
      Suporte: this.handleProfileRoutesSuporte(location),
      Assistencial: this.handleProfileRoutesAssistent(location),
      Médico: this.handleProfileRoutesMedical(location),
    };

    return perfils[perfil.user.id_perfil.descricao];
  }

  render() {
    const { classes, userLogin, getNotifications, getIdNotifications } =
      this.props;
    let id_user = userLogin.user.id_perfil._id;
    let location = window.location.pathname;
    let MenuButtons = [];

    MenuButtons = this.handleProfileRoutes(location);
    if (MenuButtons == undefined) {
      MenuButtons = this.handleProfileRoutes('default');
    }

    if (userLogin.user.id_perfil.descricao === 'Empresa - Administrativo' || userLogin.user.id_perfil.descricao === 'Empresa - Administrativo 2') {
      MenuButtons = this.handleDefaultProfileRoutes();
    }

    if (userLogin.user.id_perfil.descricao === 'Administrativo') {
      MenuButtons = this.handleMenuButtonsPerfilSegregation(
        userLogin,
        location
      );
    }
    if (userLogin.user.id_perfil.descricao === 'Suporte') {
      MenuButtons = this.handleMenuButtonsPerfilSegregation(
        userLogin,
        location
      );
    }
    if (userLogin.user.id_perfil.descricao === 'Assistencial') {
      MenuButtons = this.handleMenuButtonsPerfilSegregation(
        userLogin,
        location
      );
    }
    if (userLogin.user.id_perfil.descricao === 'Médico') {
      MenuButtons = this.handleMenuButtonsPerfilSegregation(
        userLogin,
        location
      );
    }

    return (
      <Content>
        <Header>
          <Container maxWidth='xl'>
            <GridContainer container alignItems='center'>
              <Grid item sm={3} xs={6}>
                <LogoCr to='/adm'>
                  <img width='120px' alt='Logo Central' src={logoCentral} />
                </LogoCr>
              </Grid>
              <Grid item sm={6}>
                <Title>
                  <h1>Histórico</h1>
                </Title>
              </Grid>
              <Grid item sm={3} xs={6}>
                <Profile>
                  <button
                    style={{
                      display: 'block',
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                    onClick={this.openNotification}
                  >
                    <StyledBadge
                      style={{}}
                      color='secondary'
                      badgeContent={this.state.cont}
                    >
                      <NotificationsIcon
                        style={{
                          color: 'white',
                          marginTop: 3,
                          position: 'float',
                          marginLeft: 10,
                          fontSize: '28px',
                        }}
                      />
                    </StyledBadge>
                  </button>
                  <MenuLogado
                    bt_name={userLogin.user.nome}
                    MenuButtons={MenuButtons}
                  />
                </Profile>
              </Grid>
            </GridContainer>
            <GridContainer container alignItems='center'>
              <Grid item xs={12}>
                <TitleSm>
                  <h1>Histórico</h1>
                </TitleSm>
              </Grid>
            </GridContainer>
          </Container>
        </Header>
        {this.props.children}
        <ModalComunicados
          refThisOrigin={this}
          open={this.state.notificationOpen}
          close={this.closeNotification}
          data={this.state.arr}
          userLogin={userLogin}
          dispatch={this.props.dispatch}
        />
      </Content>
      // <div className={classes.header}>
      //   <div id='menu' className={classes.main}>
      //     <Grid item xs={1}></Grid>
      //     <Grid item xs={2}>
      //       <Fab
      //         disableRipple={true}
      //         variant='extended'
      //         aria-label='Delete'
      //         className={classes.fab}
      //         style={{
      //           background: 'transparent',
      //           marginTop: '-15px',
      //           padding: '0px',
      //           border: '0',
      //           boxShadow: 'none',
      //           outline: '0',
      //         }}
      //         onClick={this.handleHomeLogo}
      //       >
      //         <img
      //           style={{
      //             margin: '0 0 0 0',
      //             zIndex: '1',
      //             filter: 'brightness(0) invert(1)',
      //           }}
      //           width='120px'
      //           alt='Logo Central'
      //           src={logoCentral}
      //         />
      //       </Fab>
      //     </Grid>
      //     <Grid
      //       className={classes.padding_top}
      //       style={{ textAlign: 'center', color: 'white', fontSize: '20px' }}
      //       item
      //       xs={6}
      //     >
      //       <div className={classes.headerTitle}>{stepper}</div>
      //     </Grid>
      //     <Grid
      //       style={{
      //         display: 'flex',
      //         flexDirection: 'row',
      //         textAlign: 'right',
      //       }}
      //       item
      //       xs={2}
      //     >
      //       <button
      //         style={{
      //           display: 'block',
      //           background: 'none',
      //           border: 'none',
      //           cursor: 'pointer',
      //         }}
      //         onClick={this.openNotification}
      //       >
      //         <StyledBadge
      //           style={{}}
      //           color='secondary'
      //           badgeContent={this.state.cont}
      //         >
      //           <NotificationsIcon style={{ color: 'white', marginTop: 6 }} />
      //         </StyledBadge>
      //       </button>
      //       <MenuLogado
      //         bt_name={userLogin.user.nome}
      //         MenuButtons={MenuButtons}
      //       />
      //     </Grid>
      //     <Grid item xs={1}></Grid>
      //     {this.props.children}
      //     <ModalComunicados
      //       refThisOrigin={this}
      //       open={this.state.notificationOpen}
      //       close={this.closeNotification}
      //       data={this.state.arr}
      //       userLogin={userLogin}
      //       dispatch={this.props.dispatch}
      //     />
      //   </div>
      // </div>
    );
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any,
};

function mapStateToProps(state) {
  const { message, type, loading } = state.alert;
  const userLogin = state.authentication.user;
  const { openPassword } = state.password;
  const { getNotifications } = state.getNotifications;
  const { getIdNotifications } = state.getIdNotifications;
  return {
    userLogin,
    message,
    type,
    loading,
    openPassword,
    getIdNotifications,
    getNotifications,
  };
}

const connnectedMenuPainel = connect(mapStateToProps)(Menu);
export default withStyles(styles)(connnectedMenuPainel);
