import './App.css';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { history } from '../helpers';
import logoCentral from '../img/logo_central.svg';
import './App.css';
import Sino from './Lib/assets/icons/Icon-sino.svg';

import {
  actionNotification,
  alertActions,
  passwordActions,
  socketInfos,
  subMenuActions,
  userActions
} from '../actions';

import Badge from '@material-ui/core/Badge';
import ModalComunicados from './Comunicados/ModalComunicados';
import Validations from './Comunicados/validations/validations';
import MenuLogado from './MenuLogadoNew';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SchedulePauses } from '../Spread/modules';

const loggedUser = localStorage.getItem('user');
let timeInterval = null;
const getRouteRedirect = (user) => {
  let profile = JSON.parse(user);
  profile =
    profile?.user?.access[0]?.profile?.description.toLowerCase() ??
    profile?.user?.id_perfil?.descricao.toLowerCase();
  const profileType = profile;

  const views = {
    suporte: '/adm/scales',
    gestor: '/adm/scales',
    administrativo: '/adm/scales/me',
    'suporte técnico': '/adm/scales',
    'Suporte Técnico': '/adm/scales',
  };

  const routeRedirect = views[profileType] || '';

  return routeRedirect;
};

const collectionPush = {
  acionamento: '/adm',
  acionamentonew: '/adm',
  historico: '/historico',
  historicoSuporte: '/historico/suporte',
  atividades: '/adm/atividades',
  cancelamentosAtivos: '/canc/ativos',
  cancelamentosFinalizados: '/canc/finalizados',
  teletriagem: '/teletriagem',
  desvinculo: '/adm/desvinculo',
  suporte: '/suporte',
  portalConecta: '/portalConecta',
  //contingencia: '/contingencia',
  comunicados: '/adm/comunicados',
  centralRelacionamento: '/adm/search',
  responsalvelFinanceiro: '/adm/responsavelfinanceiro',
  ...(loggedUser
    ? {
        escalas: getRouteRedirect(loggedUser),
      }
    : {}),
};

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -1,
    top: 14,
    padding: '0 4px',
    height: '20px',
    width: '15px',
  },
}))(Badge);

const styles = (theme) => ({
  header: {
    alignItems: 'center',
    background: '#f1f4f6',
    display: 'flex',
    justifyContent: 'center',
    '@media only screen and (min-width: 1400px)': {
      width: '100%',
    },
    '@media only screen and (max-width: 1400px)': {
      minWidth: 1300,
    },
  },
  main: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'Inter',
    fontWeight: 600,
    height: 70,
    justifyContent: 'space-between',
    paddingTop: '15px',
    '@media only screen and (min-width: 1400px)': {
      width: '100%',
    },
    '@media only screen and (max-width: 1400px)': {
      minWidth: 1300,
    },
  },
  button: {
    background: 'none',
    border: '0',
    '@media only screen and (max-width: 900px)': {
      display: 'none',
    },
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
  },
  text_banner: {
    fontSize: '60px',
    float: 'right',
    padding: '70px 30px 30px 30px',
    color: 'black',
    marginRight: '20%',
    fontWeight: '200',
    lineHeight: '1.2',
    textShadow: '#194082 0.01em 0.01em 0.2em',
    '@media only screen and (max-width: 900px)': {
      fontSize: '40px',
      float: 'right',
      padding: '60px 5% 30px 0px',
      color: 'black',
      marginRight: '0',
      fontWeight: '200',
      lineHeight: '1.2',
      textShadow: '#194082 0.01em 0.01em 0.2em',
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.black,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },

  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.6,
    transition: theme.transitions.create('opacity'),
  },

  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 1}px ${
      theme.spacing.unit + 1
    }px`,
    fontSize: '10px',
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.black,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
  card: {
    maxWidth: 345,
  },
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: 'cover',
  },
  menubar: {
    width: '100%',
  },
  showNone: {
    '@media only screen and (max-width: 900px)': {
      display: 'none',
    },
  },
  menuMobile: {
    display: 'none',
    float: 'right',
    margin: '0 10px',
    '@media only screen and (max-width: 900px)': {
      display: 'block',
      padding: '0 0 20px 0',
    },
  },
  padding_top: {
    alignItems: 'center',
    display: 'flex',
    height: 70,
    justifyContent: 'center',
  },
});

const Header = styled.header`
  align-items: center;
  display: flex;
  height: 70px;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 30px;
  @media (min-width: 1440px) {
    max-width: 1440px;
  }
`;

const Logo = styled.div`
  & img {
    width: 120px;
  }
`;

const Title = styled.div`
  & h1 {
    font-size: 23px;
    text-align: center;
  }
`;

const Profile = styled.div`
  display: flex;
`;

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalAttendance: 0,
      menuOpen: false,
      notificationOpen: false,
      arr: [],
      cont: 0,
    };
    this.logout = this.logout.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.openNotification = this.openNotification.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
  }
  state = {
    open: false,
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleHome() {
    history.push('/adm');
  }

  logout() {
    this.props.dispatch(userActions.logout());
    this.props.dispatch(alertActions.clear());
    localStorage.removeItem('user');
    history.push('/login');
  }

  handleChangePassword() {
    let location = window.location.pathname;
    if (location != '/contingencia') this.handleHome();
    this.props.dispatch(passwordActions.changePassword());
  }

  handleChangeScales() {
    this.handleHome();
    this.props.dispatch(passwordActions.changePassword());
  }

  openNotification() {
    this.setState({
      notificationOpen: true,
    });
  }
  closeNotification() {
    this.setState({
      notificationOpen: false,
    });
  }

  async notificationsUpdates(userLogin, state) {
    return await actionNotification
      .NotificationsWithoutRedux(userLogin)
      .then(async (res) => {
        const user = JSON.parse(window.localStorage.user);
        const perfil = user?.user?.id_perfil?.descricao;
        let notifyIds = await actionNotification.getIdNotificationsWithoutRedux(
          user
        );
        let resultValidations = await Validations(res.docs, notifyIds, perfil);

        state.setState({
          arr: resultValidations,
          cont: resultValidations.docs.length,
        });
      });
  }

  getProfile() {
    const user = JSON.parse(window.localStorage.user);
    return user?.user?.id_perfil?.descricao;
  }

  async componentWillMount() {
    const { socket } = this.props;
    if (socket) {
      collectionPush.escalas = getRouteRedirect(window.localStorage.user);

      // socket.on('waitRoomsChange', (payload) => {
      //   this.props.dispatch(
      //     subMenuActions.getAllSubMenuItems(JSON.parse(payload))
      //   );
      // });

      socket.on('changeDataFilasSchema', (payload) => {
        this.props.dispatch(subMenuActions.getAllSubMenuItems(payload));
      });

      socket.on('changeData', (payload) => {
        this.props.dispatch(socketInfos.socketTransportInfos(payload));
      });
      socket.on('updateAttendance', (payload) => {
        this.props.dispatch(socketInfos.socketTransportInfos(payload));
      });
    }
  }

  componentWillUnmount() {}

  handlePush(string) {
    history.push(string);
  }

  handleStructMenu(array) {
    let data = [];
    const objects = [
      {
        id: 1,
        url: '#',
        title: 'Trocar senha',
        width: '10%',
        click: this.handleChangePassword,
        height: '10px',
      },
      {
        id: '#',
        url: '#',
        title: 'Sair',
        width: '10%',
        click: this.logout,
        height: '10px',
      },
    ];

    const menu = array.map((element) => {
      data.push(this.handleMenu(element));
    });

    return [...data, ...objects];
  }

  handleMenu(title) {
    const push = {
      Acionamentos: () => {
        this.handlePush(collectionPush.acionamento);
      },
      /*       "Acionamento Vonage": () => {
        this.handlePush(collectionPush.vonage);
      }, */
      'Acionamentos - New': () => {
        this.handlePush(collectionPush.acionamentonew);
      },
      'Cancelamentos - B2C': () => {
        this.handlePush(collectionPush.cancelamentosAtivos);
      },
      'Vínculo Claro-Empresas': () => {
        this.handlePush(collectionPush.desvinculo);
      },
      'Atividades de registro': () => {
        this.handlePush(collectionPush.atividades);
      },
      Histórico: () => {
        this.handlePush(collectionPush.historico);
      },
      'Histórico de Suporte': () => {
        this.handlePush(collectionPush.historicoSuporte);
      },
      'Portal Conecta': () => {
        this.handlePush(collectionPush.portalConecta);
      },
      'Atividades de registro': () => {
        this.handlePush(collectionPush.atividades);
      },
      Comunicados: () => {
        this.handlePush(collectionPush.comunicados);
      },
      'Central de Relacionamento': () => {
        this.handlePush(collectionPush.centralRelacionamento);
      },
      'Responsável Financeiro': () => {
        this.handlePush(collectionPush.responsalvelFinanceiro);
      },
      'Escalas/Pausas': () => {
        this.handlePush(collectionPush.escalas);
      },
    };
    const menuItem = {
      id: '#',
      url: '#',
      title: title,
      width: '10%',
      click: push[title],
      height: '10px',
    };
    return menuItem;
  }
  handlerSearch() {
    history.push('/adm/search');
  }
  handleProfileRoutes(location) {
    return this.handleStructMenu([
      'Acionamentos',
      'Histórico',
      'Central de Relacionamento',
      'Responsável Financeiro',
      'Portal Conecta',
      //'Contingência',
      'Atividades de registro',
      'Histórico de Suporte',
      'Cancelamentos - B2C',
      'Escalas/Pausas',
    ]);
  }

  handleProfileRoutesAdm(location) {
    return this.handleStructMenu([
      'Acionamentos',
      'Histórico',
      'Central de Relacionamento',
      'Escalas/Pausas',
    ]);
  }

  handleProfileRoutesAssistent(location) {
    return this.handleStructMenu([
      'Acionamentos',
      'Histórico',
      'Central de Relacionamento',
      'Responsável Financeiro',
    ]);
  }

  handleProfileRoutesContingency(location) {
    return this.handleStructMenu([]);
  }

  handleProfileRoutesSuporte(location) {
    return this.handleStructMenu([
      'Acionamentos',
      'Histórico',
      'Central de Relacionamento',
      'Portal Conecta',
      //'Contingência',
      'Atividades de registro',
      'Responsável Financeiro',
      'Cancelamentos - B2C',
      'Histórico de Suporte',
      'Escalas/Pausas',
    ]);
  }

  handleDefaultProfileRoutes() {
    return this.handleStructMenu([]);
  }

  handleMenuButtonsPerfilSegregation(perfil, location) {
    const perfils = {
      Administrativo: this.handleProfileRoutesAdm(location),
      Suporte: this.handleProfileRoutesSuporte(location),
      Assistencial: this.handleProfileRoutesAssistent(location),
      Contingência: this.handleProfileRoutesContingency(location),
      'Gerador de Link': this.handleProfileRoutesContingency(location),
    };

    return (
      perfils[perfil.user.id_perfil.descricao] ??
      perfils[perfil.access[0].profile.description]
    );
  }

  render() {
    const { stepper, userLogin } = this.props;

    let location = window.location.pathname;
    let MenuButtons = [];
    MenuButtons = this.handleProfileRoutes(location);
    if (MenuButtons == undefined) {
      MenuButtons = this.handleProfileRoutes('default');
    }
    const profileDescription =
      userLogin?.user?.id_perfil?.descricao ??
      userLogin.user.access[0].profile.description;

    if (!profileDescription) {
      return <></>;
    }

    if (profileDescription === 'Empresa - Administrativo' || profileDescription === 'Empresa - Administrativo 2') {
      MenuButtons = this.handleDefaultProfileRoutes();
    }
    const profilesTypeContingencia = ['Gerador de Link', 'Contingência'];
    if (profilesTypeContingencia.includes(profileDescription)) {
      MenuButtons = this.handleMenuButtonsPerfilSegregation(
        userLogin,
        location
      );
    }

    if (profileDescription === 'Administrativo') {
      MenuButtons = this.handleMenuButtonsPerfilSegregation(
        userLogin,
        location
      );
    }
    if (profileDescription === 'Suporte') {
      MenuButtons = this.handleMenuButtonsPerfilSegregation(
        userLogin,
        location
      );
    }
    if (profileDescription === 'Assistencial') {
      MenuButtons = this.handleMenuButtonsPerfilSegregation(
        userLogin,
        location
      );
    }
    return (
      <>
        <Header>
          <Logo>
            <Link to='/adm'>
              <img src={logoCentral} alt='Logo Central' />
            </Link>
          </Logo>
          <Title>
            <h1>{stepper}</h1>
          </Title>
          {profileDescription === 'Administrativo' && <SchedulePauses />}
          <Profile>
            <button
              style={{
                display: 'block',
                background: 'none',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={this.openNotification}
            >
              <StyledBadge
                style={{}}
                color='secondary'
                badgeContent={this.state.cont}
              >
                <img
                  src={Sino}
                  style={{
                    color: 'black',
                    marginTop: 3,
                    position: 'float',
                    marginLeft: 10,
                    fontSize: '28px',
                  }}
                />
              </StyledBadge>
            </button>
            <MenuLogado
              bt_name={userLogin.user.nome}
              MenuButtons={MenuButtons}
            />
          </Profile>
        </Header>

        {this.props.children}

        <ModalComunicados
          refThisOrigin={this}
          open={this.state.notificationOpen}
          close={this.closeNotification}
          data={this.state.arr}
          userLogin={userLogin}
          dispatch={this.props.dispatch}
          notificationsUpdates={this.notificationsUpdates}
        />
      </>
    );
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { message, type, loading } = state.alert;
  const userLogin = state.authentication.user;
  const { openPassword } = state.password;
  const { getNotifications } = state.getNotifications;
  const { getIdNotifications } = state.getIdNotifications;
  return {
    userLogin,
    message,
    type,
    loading,
    openPassword,
    getIdNotifications,
    getNotifications,
  };
}

const connnectedMenuPainel = connect(mapStateToProps)(Menu);
export default withStyles(styles)(connnectedMenuPainel);
