const acceptedProfiles = [
  'Gestor',
  'Gestor da Unidade de Negócio',
  'Administrativo',
  'Suporte',
  'Suporte Técnico',
  'Empresa - Administrativo',
  'Empresa - Administrativo 2',
  'Assistencial',
  'Médico',
  'Contingência',
  'Gerador de Link',
];

export const filterAvailableProfileDescriptions = (profileDescription) =>
  acceptedProfiles.find(
    (acceptedProfile) => acceptedProfile === profileDescription
  );

export const filterAccessByProfileDescription = (accesses = []) => {
  const userAccess = accesses.find((access) => {
    return filterAvailableProfileDescriptions(access.profile.description);
  });
  return userAccess?.profile?.description;
};

export const checkCreateEncounterPermission = (profile) => {
  return acceptedProfiles
    .filter((acceptedProfile) =>
      ['Gestor', 'Suporte'].includes(acceptedProfile)
    )
    .includes(profile);
};
