import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import {
  acionamentoActions,
  alertActions,
  alertContingenciaActions,
  userActions,
} from '../actions';
import Alert from '../components/Alert';
import AlertLink from '../components/AlertLink';
import { getNavigatorInfos } from '../components/Lib/utils/utils';
import ModalConvenio from '../components/ModalConvenio';
import { history } from '../helpers';
import { validations } from '../helpers/validations';
import iconconvenio from '../icons/icon_convenio.svg';
import data from '../icons/icon_data.svg';
import sexo from '../icons/icon_gen.svg';
import pciente from '../icons/icon_paciente.svg';
import { acionamentoService } from '../services/acionamento.service';
import './App.css';

const styles = (theme) => ({
  main: {
    padding: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '700px',
    width: '100%',
    fontFamily: 'Poppins',
    paddingTop: '0',
    margin: '0 auto 0 auto',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  withoutLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  textField: {
    flexBasis: 200,
  },
  input: {
    width: '100%',
    padding: '8px 0 8px 0',
    fontSize: '15px',
  },
  img_inputs: {
    top: '10px !important',
    width: '20px',
    height: '20px',
  },
  img_inputs_sem: {
    width: '0 !important',
    height: '0 !important',
  },
  formulario: {
    margin: 0,
    padding: 0,
    width: '100%',
  },
  tituloPagina: {
    color: 'black',
    fontFamily: 'Arial',
    fontSize: '32px !important',
    fontWeight: 'bold',
  },
  erroValidation: {
    color: 'red',
  },
  alertBox: {
    zIndex: '10001 !important',
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '10000',
    backgroundPosition: 'center center no-repeat',
  },
  alertText: {
    margin: '3px 0 0 0',
    fontSize: '9px',
    color: 'red',
  },
  alertTextTop: {
    marginTop: -15,
  },
  alertTextBottom: {
    marginTop: -8,
  },
  inputMobile: {
    width: '50%',
    '@media only screen and (max-width: 900px)': {
      width: '100%',
    },
  },
  inputBirth: {
    width: '100%',
    padding: '8px 0 0 0',
    fontSize: '12px',
    '#::placeholder': {
      fontSize: '12px',
    },
  },
  input_phone: {
    fontSize: '12px',
    padding: '10px 0 2px 0',
    '#::placeholder': {
      fontSize: '12px',
    },
    '@media only screen and (max-width: 900px)': {
      width: '100%',
    },
  },
});

class Acionamento extends React.Component {
  constructor(props) {
    props.dispatch(acionamentoActions.getRegionsByCountry());
    props.dispatch(acionamentoActions.getCityByRegions());
    props.dispatch(acionamentoActions.getAllCountryAcionamentos());
    props.dispatch(acionamentoActions.getAllConvenios());
    super(props);
    this.state = {
      birthDate: '    -    ',
      numberformat: '1320',
      user: {
        cpfrequire: false,
        name: '',
        lastName: '',
        cpf: '',
        email: '',
        birthDate: '',
        password: '',
        cpassword: '',
        loadingsub: false,
        submitted: false,
        checkTerms: false,
        showNotifications: false,
        numberCard: '',
        userType: '',
        sex: '',
        phone: '',
        phone2: '',
        phone3: '',
        rg: '',
        passport: '',
        convenio: '',
        nacionalidade: '',
        pais: '',
        codPais: '',
        city: '',
        id_requisicao_parceiro: '',
        regiao: '',
      },
      country: {
        result: [],
      },
      aliasConvenio: null,
      convenioValid: false,
      openModal: false,
      openModalConfirmacao: false,
      blurPhone: false,
      message: '',
      type: '',
      open: false,
      paisValid: false,
      regionValid: false,
      cityValid: false,
      vertical: 'top',
      horizontal: 'center',
      valDate: 'text',
      submitted: false,
      registry: false,
      formValid: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitConfirmed = this.handleSubmitConfirmed.bind(this);
    this.TextMaskCustom = this.TextMaskCustom.bind(this);
    this.TextMaskCustomCPF = this.TextMaskCustomCPF.bind(this);
    this.PhoneMask = this.PhoneMask.bind(this);
    this.PhoneMask2 = this.PhoneMask2.bind(this);
    this.PhoneMask3 = this.PhoneMask3.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseAlertLink = this.handleCloseAlertLink.bind(this);
    this.handleClickOpenModalConvenio =
      this.handleClickOpenModalConvenio.bind(this);
    this.handleCloseModalConvenio = this.handleCloseModalConvenio.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleValidFormulario = this.handleValidFormulario.bind(this);
    this.validationCadastro = this.validationCadastro.bind(this);
    this.handleClickOpenModalConfirmacao =
      this.handleClickOpenModalConfirmacao.bind(this);
    this.handleCloseModalConfirmacao =
      this.handleCloseModalConfirmacao.bind(this);
    this.handleShowNotification = this.handleShowNotification.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleChangePais = this.handleChangePais.bind(this);
    this.handleChangeRegiao = this.handleChangeRegiao.bind(this);
    this.handleChangeRegiaoKeyDown = this.handleChangeRegiaoKeyDown.bind(this);
    this.handleChangeCidadeKeyDown = this.handleChangeCidadeKeyDown.bind(this);
    this.handleChangePaisKeyDown = this.handleChangePaisKeyDown.bind(this);
    this.handleChangeCity = this.handleChangeCity.bind(this);
    this.handleChangePaisOnBlur = this.handleChangePaisOnBlur.bind(this);
    this.handleChangeRegiaoOnBlur = this.handleChangeRegiaoOnBlur.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }
  handleChangePaisOnBlur(evt) {
    this.handleChangePaisKeyDown(evt);
    this.validationCadastro('pais');
  }

  handleChangeRegiaoOnBlur(evt) {
    this.handleChangeRegiaoKeyDown(evt);
    this.validationCadastro('regiao');
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  }

  handleChangePais(event, values) {
    let val = {};
    switch (values) {
      case null:
        val = {
          name: '',
          code: '',
        };
        break;
      default:
        val = {
          ...values,
          name: !values.hasOwnProperty('name') ? '' : values.name,
          code: !values.hasOwnProperty('code') ? '' : values.code,
        };
        break;
    }
    const { name, code } = val;
    const { user } = this.state;

    let json = {
      Brasil: 'br',
      Brazil: 'br',
      'United States of America': 'us',
      'Estados Unidos': 'us',
    };

    this.setState({
      user: {
        ...user,
        streetCep: '',
        pais: name,
        codPais: json[name],
        regiao: '',
      },
    });

    this.props.dispatch(acionamentoActions.getRegionsByCountry(json[name]));
  }

  handleChangeRegiaoKeyDown(event) {
    const { name, value } = event.target;
    let val = {};
    switch (value) {
      case null:
        val = {
          region: '',
        };
        break;
      default:
        val = {
          region: value,
        };
        break;
    }
    const { region } = val;
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        regiao: region,
        city: '',
      },
    });
  }

  handleChangePaisKeyDown(event) {
    const { value } = event.target;
    let val = {};
    switch (value) {
      case null:
        val = {
          name: '',
        };
        break;
      default:
        val = {
          name: value,
        };
        break;
    }

    const { name, code } = val;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        pais: name,
        regiao: '',
      },
    });
  }

  handleChangeCity(event, values) {
    let val = {};
    switch (values) {
      case null:
        val = {
          Estado: '',
          Nome: '',
          ID: '',
        };
        break;
      default:
        val = {
          ...values,
          Nome: !values.hasOwnProperty('Nome') ? '' : values.Nome,
        };
        break;
    }

    const { Nome } = val;
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        city: Nome,
      },
    });
  }

  handleChangeRegiaoKeyDown(event) {
    const { name, value } = event.target;
    let val = {};
    switch (value) {
      case null:
        val = {
          region: '',
        };
        break;
      default:
        val = {
          region: value,
        };
        break;
    }
    const { region } = val;
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        regiao: region,
        city: '',
      },
    });
  }

  handleChangeRegiao(event, values) {
    let val = {};
    switch (values) {
      case null:
        val = {
          region: '',
        };
        break;
      default:
        val = {
          ...values,
          region: !values.hasOwnProperty('region') ? '' : values.region,
        };
        break;
    }

    const { region } = val;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        regiao: region,
        city: '',
      },
    });
    this.props.dispatch(acionamentoActions.getCityByRegions(region));
  }

  async componentDidMount() {
    let { browserName, fullVersion, so } = getNavigatorInfos(window);

    let deviceData = {
      deviceType: '',
      OS: so,
      navigator: browserName,
      route: '/create',
      accessChannel: 'web',
      appVersion: fullVersion,
      deviceBrand: 'manual-central',
    };
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      let id = user?.access[0]?.health_insurance_data[0]?.portal_empresas_id;

      let descricao =
        user?.access[0]?.health_insurance_data[0]?.descricao_convenio;
      this.setState({
        user: {
          convenio: `${id}|${descricao}`,
          nacionalidade: '',
          deviceData: deviceData,
        },
      });
    }
  }

  async componentWillReceiveProps(received) {
    const docs = async () => await this.props.deletedDocs;
    this.setState({ deletedDocs: docs });

    let { user } = this.state;

    if (!(!user.streetCep && !user.pais)) {
      if (user.streetCep.length === 9) {
        const address = await this.getAddress(user.streetCep);
        if (address) {
          let { regions } = received;
          this.setState({ loading: false });
          let state = {};
          if (regions.length > 0 && regions[0].states) {
            regions[0].states.map((reg) => {
              if (reg.letters == address.uf) return (state = reg);
            });
            this.setState({
              cepValid: true,
              user: {
                ...user,
                pais: 'Brasil',
                codPais: 'br',
                regiao: state.region,
                city: address.localidade,
                cepToAddress: address,
              },
            });
          }
        }
      }
    }
  }

  async sendEmail(url) {
    try {
      let { name, lastName, socialName, email, email2 } = this.state.user;

      await acionamentoService
        .sendEmail(name, lastName, socialName, email, email2, url)
        .then(
          this.props.dispatch(
            alertActions.success('Link enviado por e-mail com sucesso!')
          )
        );
    } catch (erro) {
      this.props.dispatch(
        alertActions.error('Falha ao enviar link por e-mail')
      );
    }
  }

  handleChangeCidadeKeyDown(event) {
    const { name, value } = event.target;
    let val = {};
    switch (value) {
      case null:
        val = {
          Nome: '',
        };
        break;
      default:
        val = {
          Nome: value,
        };
        break;
    }
    const { Nome } = val;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        city: Nome,
      },
    });
  }

  handleValidFormulario(event, edit) {
    event.preventDefault();

    this.validationCadastro('pais');
    this.validationCadastro('regiao');
    this.validationCadastro('city');

    this.setState({ submitted: true });
    const { user } = this.state;
    var birthDate = user.birthDate;
    if (birthDate) {
      var arrayDate = birthDate.split('/');
      var day = Number(arrayDate[0]),
        month = Number(arrayDate[1]),
        year = Number(arrayDate[2]);

      var formBirth = moment([year, month, day]);
      var now = moment();
      var years = now.diff(formBirth, 'years');
      if (years >= 18 && !this.state.user.cpf) {
        this.setState({
          cpfrequire: true,
        });
      } else {
        this.setState({
          cpfrequire: false,
        });
      }
    }
    if (this.state.formValid) {
      this.handleClickOpenModalConfirmacao();
    }
  }

  handleSubmit = () => {
    const { user } = this.state;
    const { dispatch } = this.props;
    if (user.convenio !== '') {
      dispatch(userActions.registerEmpAdm(user));
      this.handleCloseModalConfirmacao();
    }
  };

  handleSubmitEdit = () => {
    const { user } = this.state;
    const { dispatch } = this.props;
    dispatch(userActions.put(user));
    this.handleCloseModalConvenio();
    this.handleBack();
  };

  handleSubmitConfirmed = () => {
    const { user } = this.state;
    const { dispatch } = this.props;
    if (user.convenio !== '') {
      dispatch(acionamentoActions.confirmedAcionamentos(user));
      this.handleCloseModalConfirmacao();
    } else {
      this.setState({
        convenioValid: true,
      });
    }
  };

  handleShowNotification = () => {
    const { user } = this.state;

    if (this.state.user.showNotifications) {
      this.setState({
        user: {
          ...user,
          showNotifications: false,
        },
      });
    } else {
      this.setState({
        user: {
          ...user,
          showNotifications: true,
        },
      });
    }
  };

  handleChangeCheckbox = () => {
    const { user } = this.state;
    if (user.checkTerms) {
      this.setState({
        user: {
          ...user,
          checkTerms: false,
        },
      });
    } else {
      this.setState({
        user: {
          ...user,
          checkTerms: true,
        },
      });
    }
    this.validationCadastro('terms');
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleClickShowCPassword = () => {
    this.setState((state) => ({ showCPassword: !state.showCPassword }));
  };

  handleClose() {
    this.props.dispatch(alertActions.clear());
    this.setState({ open: false, message: '', loadingsub: false });
  }

  handleClear() {
    this.setState({
      user: {
        name: '',
        lastName: '',
        cpf: '',
        email: '',
        birthDate: '',
        password: '',
        cpassword: '',
        loadingsub: false,
        submitted: false,
        checkTerms: false,
        showNotifications: false,
        sex: '',
        phone: '',
        phone2: '',
        phone3: '',
        rg: '',
        passport: '',
        nacionalidade: '',
        id_requisicao_parceiro: '',
      },
    });
  }

  validationCadastro(event) {
    const {
      name,
      lastName,
      nacionalidade,
      cpf,
      passport,
      email,
      birthDate,
      password,
      cpassword,
      checkTerms,
      sex,
      phone,
      rg,
      id_requisicao_parceiro,
      pais,
      regiao,
      city,
      numberCard,
    } = this.state.user;
    const { userLogin } = this.props;
    let showNCar = this.handleCheckNumberCard(userLogin.dataConvenio?.id);
    let validateNumberCard = showNCar ? numberCard : true;

    switch (event) {
      case 'name':
        if (name) {
          this.state.nameValid = validations.validationName(name);
        }
        break;
      case 'lastName':
        if (lastName) {
          this.state.lastNameValid = validations.validationLastName(lastName);
        }
        break;
      case 'cpf':
        if (cpf) {
          this.state.cpfValid = validations.validationCPF(cpf);
        }
        break;
      case 'email':
        if (email) {
          this.state.emailValid = validations.validationEmail(email);
        }
        break;
      case 'birthDate':
        if (birthDate) {
          this.state.birthDateValid =
            validations.validationbirthDate(birthDate);
        }
        break;
      case 'phone':
        if (phone) {
          this.state.phoneValid = validations.validationTelefone(phone);
        }
        break;
      case 'terms':
        this.state.termsValid = validations.validationTerms(checkTerms);
        break;
      case 'password':
        if (password) {
          this.state.passwordValid = validations.validationPassword(password);
        }
        break;
      case 'cpassword':
        if (cpassword) {
          this.state.cpasswordValid = validations.validationCPassword(
            password,
            cpassword
          );
        }
        break;
      case 'rg':
        if (rg) {
          this.state.rgValid = validations.validationRg(rg);
        }
        break;
      case 'id_requisicao_parceiro':
        if (id_requisicao_parceiro) {
          this.state.id_requisicao_parceiroValid =
            validations.validationIdRequisicaoParceiro(id_requisicao_parceiro);
        }
        break;
      case 'pais':
        if (pais) {
          this.state.paisValid = validations.validatePais(pais);
        }
        break;
      case 'regiao':
        this.state.regionValid = validations.validateRegion(
          regiao,
          this.state.user.codPais
        );
        break;
      case 'city':
        this.state.cityValid = validations.validateCity(
          city,
          this.state.user.codPais
        );
        break;
      default:
        break;
    }
    const {
      nameValid,
      lastNameValid,
      emailValid,
      passwordValid,
      cpasswordValid,
      termsValid,
      birthDateValid,
      paisValid,
      regionValid,
      cpfValid,
      rgValid,
      id_requisicao_parceiroValid,
      phoneValid,
    } = this.state;

    if (nacionalidade === 'B') {
      if (birthDate) {
        var formBirth = moment(birthDate, 'DD/MM/YYYY');
        var now = moment();
        var years = now.diff(formBirth, 'years');
        if (
          years >= 18 &&
          (!this.state.user.cpf || this.state.user.cpf == '')
        ) {
          this.state.formValid =
            validateNumberCard &&
            phoneValid &&
            cpfValid &&
            rg &&
            nacionalidade &&
            !nameValid &&
            !lastNameValid &&
            email &&
            email != '' &&
            lastName != undefined &&
            lastName != '' &&
            !emailValid &&
            !passwordValid &&
            !cpasswordValid &&
            !termsValid &&
            !birthDateValid &&
            (sex === 'M' || sex === 'F');
        } else {
          this.state.formValid =
            validateNumberCard &&
            phoneValid &&
            nacionalidade &&
            email &&
            email != '' &&
            !nameValid &&
            !lastNameValid &&
            lastName != undefined &&
            lastName != '' &&
            !emailValid &&
            !passwordValid &&
            !cpasswordValid &&
            !termsValid &&
            !birthDateValid &&
            (sex === 'M' || sex === 'F');
        }
      } else {
        this.state.formValid =
          phoneValid &&
          validateNumberCard &&
          nacionalidade &&
          !nameValid &&
          !lastNameValid &&
          lastName != undefined &&
          lastName != '' &&
          email &&
          email != '' &&
          !emailValid &&
          !passwordValid &&
          !cpasswordValid &&
          !termsValid &&
          !birthDateValid &&
          (sex === 'M' || sex === 'F');
      }
    } else {
      this.state.formValid =
        phoneValid &&
        validateNumberCard &&
        nacionalidade &&
        !nameValid &&
        !lastNameValid &&
        lastName != undefined &&
        lastName != '' &&
        passport &&
        email &&
        email != '' &&
        !emailValid &&
        !passwordValid &&
        !cpasswordValid &&
        !termsValid &&
        !birthDateValid &&
        (sex === 'M' || sex === 'F');
    }
  }

  TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        value={this.state.user.birthDate}
        onChange={this.handleChange}
        style={{ fontSize: '15px', verticalAlign: 'top' }}
        name='birthDate'
        id='formatted-text-mask-input'
        placeholder='Data de Nascimento'
        mask='99/99/9999'
        maskChar={null}
        // beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  TextMaskCustomCPF(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        value={this.state.user.cpf}
        onChange={this.handleChange}
        style={{ fontSize: '15px', verticalAlign: 'top' }}
        name='cpf'
        mask='999.999.999-99'
        maskChar={null}
        // beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  handleBack() {
    history.push('/adm');
  }

  PhoneMask(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        key={'phone'}
        style={{ fontSize: '15px', verticalAlign: 'middle' }}
        name={'phone'}
        value={this.state.user.phone}
        placeholder={'DDD + Telefone'}
        mask={
          this.state.user.nacionalidade === 'B'
            ? '(99) 999999999'
            : '99999999999999'
        }
        maskChar={null}
        onChange={this.handleChange}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }
  PhoneMask2(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        key={'phone2'}
        style={{ fontSize: '15px', verticalAlign: 'middle' }}
        name={'phone2'}
        value={this.state.user.phone2}
        placeholder={'DDD + Telefone 2'}
        mask={
          this.state.user.nacionalidade === 'B'
            ? '(99) 999999999'
            : '99999999999999'
        }
        maskChar={null}
        onChange={this.handleChange}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }
  PhoneMask3(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        key={'phone3'}
        style={{ fontSize: '15px', verticalAlign: 'middle' }}
        name={'phone3'}
        value={this.state.user.phone3}
        placeholder={'DDD + Telefone 3'}
        mask={
          this.state.user.nacionalidade === 'B'
            ? '(99) 999999999'
            : '99999999999999'
        }
        maskChar={null}
        onChange={this.handleChange}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (
      value.endsWith('-') &&
      userInput !== '-' &&
      !this.state.value.endsWith('-')
    ) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  };

  handleCloseAlertLink() {
    const { dispatch } = this.props;
    dispatch(alertContingenciaActions.clear());
  }

  handleClickOpenModalConvenio() {
    this.setState({ openModal: true });
  }

  handleCloseModalConvenio() {
    this.setState({ openModal: false });
  }

  handleClickOpenModalConfirmacao() {
    this.setState({ openModalConfirmacao: true });
  }

  handleCloseModalConfirmacao() {
    this.setState({ openModalConfirmacao: false });
  }

  handleCheckNumberCard(userNumberCard) {
    let conveniosNumberCard = JSON.parse(
      `[${process.env.REACT_APP_NUMBER_CARD}]`
    );
    return conveniosNumberCard.includes(Number(userNumberCard));
  }

  render() {
    const {
      registering,
      open,
      classes,
      message,
      type,
      convenios = [],
      countries = [],
      cities = [],
      userLogin,
    } = this.props;
    let showNCar = this.handleCheckNumberCard(userLogin.dataConvenio?.id);
    let { regions } = this.props;
    const { user, submitted, openModal, openModalConfirmacao, aliasConvenio } =
      this.state;
    const width = window.innerWidth;
    if (regions.length >= 1) {
      regions = regions[0].states;
    }

    const estados_brasileiros = [
      { region: 'Acre', country: 'br' },
      { region: 'Alagoas', country: 'br' },
      { region: 'Amapa', country: 'br' },
      { region: 'Amazonas', country: 'br' },
      { region: 'Bahia', country: 'br' },
      { region: 'Ceara', country: 'br' },
      { region: 'Espirito Santo', country: 'br' },
      { region: 'Federal District', country: 'br' },
      { region: 'Goias', country: 'br' },
      { region: 'Maranhao', country: 'br' },
      { region: 'Mato Grosso', country: 'br' },
      { region: 'Mato Grosso do Sul', country: 'br' },
      { region: 'Minas Gerais', country: 'br' },
      { region: 'Para', country: 'br' },
      { region: 'Paraiba', country: 'br' },
      { region: 'Parana', country: 'br' },
      { region: 'Pernambuco', country: 'br' },
      { region: 'Piaui', country: 'br' },
      { region: 'Rio de Janeiro', country: 'br' },
      { region: 'Rio Grande do Norte', country: 'br' },
      { region: 'Rio Grande do Sul', country: 'br' },
      { region: 'Rondonia', country: 'br' },
      { region: 'Roraima', country: 'br' },
      { region: 'Santa Catarina', country: 'br' },
      { region: 'Sao Paulo', country: 'br' },
      { region: 'Sergipe', country: 'br' },
      { region: 'Tocantins', country: 'br' },
    ];

    const estados_united = [
      { region: 'Alabama', country: 'us' },
      { region: 'Alaska', country: 'us' },
      { region: 'Arizona', country: 'us' },
      { region: 'Arkansas', country: 'us' },
      { region: 'California', country: 'us' },
      { region: 'Colorado', country: 'us' },
      { region: 'Connecticut', country: 'us' },
      { region: 'Delaware', country: 'us' },
      { region: 'District of Columbia', country: 'us' },
      { region: 'Florida', country: 'us' },
      { region: 'Georgia', country: 'us' },
      { region: 'Hawaii', country: 'us' },
      { region: 'Idaho', country: 'us' },
      { region: 'Illinois', country: 'us' },
      { region: 'Indiana', country: 'us' },
      { region: 'Iowa', country: 'us' },
      { region: 'Kansas', country: 'us' },
      { region: 'Kentucky', country: 'us' },
      { region: 'Louisiana', country: 'us' },
      { region: 'Maine', country: 'us' },
      { region: 'Maryland', country: 'us' },
      { region: 'Massachusetts', country: 'us' },
      { region: 'Michigan', country: 'us' },
      { region: 'Minnesota', country: 'us' },
      { region: 'Mississippi', country: 'us' },
      { region: 'Missouri', country: 'us' },
      { region: 'Montana', country: 'us' },
      { region: 'Nebraska', country: 'us' },
      { region: 'Nevada', country: 'us' },
      { region: 'New Hampshire', country: 'us' },
      { region: 'New Jersey', country: 'us' },
      { region: 'New Mexico', country: 'us' },
      { region: 'New York', country: 'us' },
      { region: 'North Carolina', country: 'us' },
      { region: 'North Dakota', country: 'us' },
      { region: 'Ohio', country: 'us' },
      { region: 'Oklahoma', country: 'us' },
      { region: 'Oregon', country: 'us' },
      { region: 'Pennsylvania', country: 'us' },
      { region: 'Rhode Island', country: 'us' },
      { region: 'South Carolina', country: 'us' },
      { region: 'South Dakota', country: 'us' },
      { region: 'Tennessee', country: 'us' },
      { region: 'Texas', country: 'us' },
      { region: 'Utah', country: 'us' },
      { region: 'Vermont', country: 'us' },
      { region: 'Virginia', country: 'us' },
      { region: 'Washington', country: 'us' },
      { region: 'West Virginia', country: 'us' },
      { region: 'Wisconsin', country: 'us' },
      { region: 'Wyoming', country: 'us' },
    ];

    const inputs_1 = [
      {
        float: 'left',
        imagem: pciente,
        pergunta: 'Nome',
        state: user.name,
        type: 'name',
        blur: this.validationCadastro('name'),
        nValid: this.state.nameValid,
        validacao: 'No mínimo 2 caracteres.',
      },
      {
        float: 'right',
        imagem: pciente,
        pergunta: 'Sobrenome',
        state: user.lastName,
        type: 'lastName',
        blur: this.validationCadastro('lastName'),
        nValid: this.state.lastNameValid,
        validacao: 'No mínimo 2 caracteres.',
      },
    ];

    const input_cpf = [
      {
        float: 'left',
        imagem: iconconvenio,
        pergunta: 'CPF',
        state: user.cpf,
        type: 'cpf',
        blur: this.validationCadastro('cpf'),
        nValid: this.state.cpfValid,
        require: this.state.cpfrequire,
        validacao: 'CPF inválido.',
      },
    ];

    const input_rg = [
      {
        float: 'right',
        imagem: iconconvenio,
        pergunta: 'Rg',
        state: user.rg,
        type: 'rg',
        blur: this.validationCadastro('rg'),
        nValid: this.state.rgValid,
        validacao: 'Digite o RG.',
      },
    ];

    const inputs_3 = [
      {
        imagem: pciente,
        pergunta: 'Email',
        state: user.email,
        type: 'email',
        blur: this.validationCadastro('email'),
        nValid: this.state.emailValid,
        validacao: 'Email inválido.',
      },
    ];

    const inputs_4 = [
      {
        imagem: iconconvenio,
        pergunta: 'Passaporte',
        state: user.passport,
        type: 'passport',
        blur: this.validationCadastro('passport'),
        nValid: this.state.passportValid,
        validacao: 'Passaporte inválido.',
      },
    ];

    const inputs_5 = [
      {
        imagem: iconconvenio,
        pergunta: 'Identificador parceiro',
        state: user.id_requisicao_parceiro,
        type: 'id_requisicao_parceiro',
        blur: this.validationCadastro('id_requisicao_parceiro'),
        nValid: this.state.id_requisicao_parceiroValid,
        validacao: 'Identificador parceiro inválido.',
      },
    ];

    const phones = [
      {
        inputRef: this.PhoneMask,
        float: 'left',
        text: 'Campo obrigatório.',
        blur: this.validationCadastro('phone'),
      },
      {
        inputRef: this.PhoneMask2,
        float: 'center',
      },
      {
        inputRef: this.PhoneMask3,
        float: 'right',
      },
    ];

    return (
      <div className={classes.main}>
        <Alert
          open={open}
          type={type}
          message={message}
          onClose={() => this.handleClose(type, message)}
        />
        <AlertLink
          close={() => this.handleCloseAlertLink()}
          sendEmail={this.sendEmail}
        />
        <ModalConvenio
          open={openModal}
          close={() => this.handleCloseModalConvenio()}
          message='Escolha um Convênio'
          onSubmit={() => this.handleSubmit()}
          component={
            <React.Fragment>
              <TextField
                id='standard-select-currency'
                select
                label='Convênio'
                onChange={this.handleChange}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin='normal'
                value={user.convenio}
                input={<Input id='select-multiple-placeholder' />}
                displayEmpty
                name='convenio'
                className={classes.input}
              >
                {convenios.map((convenio) => (
                  <MenuItem
                    key={convenio.id}
                    value={`${convenio.id}|${convenio.descricao}`}
                  >
                    {convenio.descricao}
                  </MenuItem>
                ))}
              </TextField>
              {this.state.convenioValid && (
                <div
                  className={classNames(
                    classes.alertText,
                    classes.alertTextTop
                  )}
                >
                  Campo obrigatório.
                </div>
              )}
            </React.Fragment>
          }
        />

        <ModalConvenio
          open={openModalConfirmacao}
          close={() => this.handleCloseModalConfirmacao()}
          message='Confirmar acionamento?'
          onSubmit={() => this.handleSubmit()}
        />

        <form
          id='Cadastro_Usuario'
          name='Cadastro_Usuario'
          className={classes.formulario}
          onSubmit={this.handleValidFormulario}
        >
          {aliasConvenio ? (
            <TextField
              id='standard-select-currency'
              select
              label='Convênio'
              onChange={this.handleChange}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              value={user.convenio}
              input={<Input id='select-multiple-placeholder' />}
              displayEmpty
              name='convenio'
              className={classes.input}
            >
              {convenios.map((convenio) => (
                <MenuItem key={convenio.id} value={user.convenio}>
                  {convenio.descricao}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            ''
          )}
          <Grid container spacing={width > 900 ? 8 : 0}>
            {inputs_1.map((questao) => (
              <Grid
                key={questao.type}
                item
                style={{ float: questao.float }}
                className={classNames(
                  classes.inputMobile +
                    (submitted && !questao.state ? ' has-error' : '')
                )}
              >
                <TextField
                  margin='normal'
                  placeholder={questao.validacao}
                  label={questao.pergunta}
                  name={questao.type}
                  value={questao.state}
                  className={classes.input}
                  key={questao.type}
                  onBlur={questao.blur}
                  type={questao.date}
                  onFocus={questao.focus}
                  onChange={this.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img
                          className={
                            questao.imagem
                              ? classes.img_inputs
                              : classes.img_inputs_sem
                          }
                          src={questao.imagem ? questao.imagem : null}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                {submitted && !questao.state && (
                  <div
                    className={classNames(
                      classes.alertText,
                      classes.alertTextTop
                    )}
                  >
                    Campo obrigatório.
                  </div>
                )}
                {questao.nValid && !(submitted && !questao.state) && (
                  <div
                    className={classNames(
                      classes.alertText,
                      classes.alertTextTop
                    )}
                  >
                    {questao.validacao}
                  </div>
                )}
              </Grid>
            ))}
          </Grid>
          {showNCar && (
            <>
              <Grid container spacing={width > 900 ? 8 : 0}>
                <Grid
                  key={'numberCard'}
                  item
                  style={{ float: 'left' }}
                  className={classNames(
                    classes.inputMobile +
                      (submitted && !user.numberCard ? ' has-error' : '')
                  )}
                >
                  <TextField
                    autoFocus
                    name='numberCard'
                    margin='dense'
                    id='numberCard'
                    label='Carteirinha'
                    type='string'
                    value={user.numberCard}
                    onChange={this.handleChange}
                    fullWidth
                  />
                  {submitted && !user.numberCard && (
                    <div className={classes.alertText}>Campo obrigatório.</div>
                  )}
                </Grid>
                <Grid
                  key={'nacionalidade'}
                  item
                  style={{ float: 'left' }}
                  className={classNames(
                    classes.inputMobile +
                      (submitted && !user.nacionalidade ? ' has-error' : '')
                  )}
                >
                  <Select
                    value={user.nacionalidade}
                    onChange={this.handleChange}
                    input={<Input id='select-multiple-placeholder' />}
                    displayEmpty
                    name='nacionalidade'
                    className={classes.input}
                    style={{ float: 'left' }}
                    startAdornment={
                      <InputAdornment position='start'>
                        <img className={classes.img_inputs} src={sexo} />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value=''>
                      <em style={{ opacity: '0.5', fontStyle: 'Normal' }}>
                        Nacionalidade
                      </em>
                    </MenuItem>
                    <MenuItem value={'B'}>Brasileira</MenuItem>
                    <MenuItem value={'E'}>Estrangeira</MenuItem>
                  </Select>
                  {submitted && !user.nacionalidade && (
                    <div className={classes.alertText}>Campo obrigatório.</div>
                  )}
                </Grid>
              </Grid>
            </>
          )}

          {!showNCar && (
            <>
              <Select
                value={user.nacionalidade}
                onChange={this.handleChange}
                input={<Input id='select-multiple-placeholder' />}
                displayEmpty
                name='nacionalidade'
                className={classes.input}
                startAdornment={
                  <InputAdornment position='start'>
                    <img className={classes.img_inputs} src={sexo} />
                  </InputAdornment>
                }
              >
                <MenuItem value=''>
                  <em style={{ opacity: '0.5', fontStyle: 'Normal' }}>
                    Nacionalidade
                  </em>
                </MenuItem>
                <MenuItem value={'B'}>Brasileira</MenuItem>
                <MenuItem value={'E'}>Estrangeira</MenuItem>
              </Select>
              {submitted && !user.nacionalidade && (
                <div className={classes.alertText}>Campo obrigatório.</div>
              )}
            </>
          )}
          <Grid>
            {user.nacionalidade === 'B' ? (
              <Grid>
                <TextField
                  label='Cpf'
                  margin='normal'
                  placeholder='Digite o CPF.'
                  className={classes.inputBirth}
                  onBlur={this.validationCadastro('cpf')}
                  InputProps={{
                    inputComponent: this.TextMaskCustomCPF,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img
                          className={classes.img_inputs}
                          src={iconconvenio}
                        />
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                {submitted && !user.cpf && this.state.cpfrequire && (
                  <div
                    className={classNames(
                      classes.alertText,
                      classes.alertTextBottom
                    )}
                  >
                    Campo obrigatório.
                  </div>
                )}
                {submitted &&
                  this.state.cpfValid &&
                  !(!user.cpf && this.state.cpfrequire) && (
                    <div
                      className={classNames(
                        classes.alertText,
                        classes.alertTextBottom
                      )}
                    >
                      {'CPF inválido.'}
                    </div>
                  )}

                {input_rg.map((questao) => (
                  <Grid>
                    <TextField
                      margin='normal'
                      placeholder={questao.validacao}
                      label={questao.pergunta}
                      name={questao.type}
                      value={questao.state}
                      className={classes.input}
                      onBlur={questao.blur}
                      type={questao.date}
                      onFocus={questao.focus}
                      onChange={this.handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <img
                              className={
                                questao.imagem
                                  ? classes.img_inputs
                                  : classes.img_inputs_sem
                              }
                              src={questao.imagem ? questao.imagem : null}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {submitted && !questao.state && this.state.cpfrequire && (
                      <div
                        className={classNames(
                          classes.alertText,
                          classes.alertTextTop
                        )}
                      >
                        Campo obrigatório.
                      </div>
                    )}
                    {questao.nValid && (
                      <div
                        className={classNames(
                          classes.alertText,
                          classes.alertTextTop
                        )}
                      >
                        {questao.validacao}
                      </div>
                    )}
                  </Grid>
                ))}
              </Grid>
            ) : (
              ''
            )}
          </Grid>
          {user.nacionalidade === 'E' ? (
            <Grid container justify='center'>
              {inputs_4.map((questao) => (
                <Grid
                  key={questao.type}
                  item
                  style={{ width: '100%' }}
                  className={'form-group'}
                >
                  <TextField
                    label='Passaporte'
                    margin='normal'
                    name={questao.type}
                    value={questao.state}
                    className={classes.input}
                    placeholder={questao.pergunta}
                    onBlur={questao.blur}
                    type={questao.date}
                    onFocus={questao.focus}
                    onChange={this.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <img
                            className={
                              questao.imagem
                                ? classes.img_inputs
                                : classes.img_inputs_sem
                            }
                            src={questao.imagem ? questao.imagem : null}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {submitted && !questao.state && (
                    <div
                      className={classNames(
                        classes.alertText,
                        classes.alertTextBottom
                      )}
                    >
                      Campo obrigatório.
                    </div>
                  )}
                  {questao.nValid && (
                    <div
                      className={classNames(
                        classes.alertText,
                        classes.alertTextBottom
                      )}
                    >
                      {questao.validacao}
                    </div>
                  )}
                </Grid>
              ))}
            </Grid>
          ) : (
            ''
          )}

          <Grid container spacing={width > 900 ? 8 : 0}>
            <Grid
              item
              style={{ float: 'left' }}
              className={classNames(classes.inputMobile, 'form-group')}
            >
              <TextField
                label='Data de Nascimento'
                margin='normal'
                className={classes.inputBirth}
                onBlur={this.validationCadastro('birthDate')}
                InputProps={{
                  inputComponent: this.TextMaskCustom,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img className={classes.img_inputs} src={data} />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
              {submitted && !user.birthDate && (
                <div
                  className={classNames(
                    classes.alertText,
                    classes.alertTextBottom
                  )}
                >
                  Campo obrigatório.
                </div>
              )}
              {submitted && this.state.birthDateValid && (
                <div
                  className={classNames(
                    classes.alertText,
                    classes.alertTextBottom
                  )}
                >
                  {'Data de nascimento inválida.'}
                </div>
              )}
            </Grid>

            <Grid
              item
              style={{ float: 'right' }}
              className={classNames(classes.inputMobile, 'form-group')}
            >
              <TextField
                id='standard-select-currency'
                select
                label='Sexo'
                onChange={this.handleChange}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin='normal'
                value={user.sex}
                input={<Input id='select-multiple-placeholder' />}
                displayEmpty
                name='sex'
                className={classes.input}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img className={classes.img_inputs} src={sexo} />
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value={'M'}>Masculino</MenuItem>
                <MenuItem value={'F'}>Feminino</MenuItem>
              </TextField>
              {submitted && !user.sex && (
                <div
                  className={classNames(
                    classes.alertText,
                    classes.alertTextTop
                  )}
                >
                  Campo obrigatório.
                </div>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            {phones.map((phone) => (
              <Grid key={phone.float} item xs={12} sm={4}>
                <TextField
                  label='Telefone'
                  margin='normal'
                  className={classes.input_phone}
                  onBlur={this.validationCadastro('phone')}
                  InputProps={{
                    inputComponent: phone.inputRef,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img className={classes.img_inputs} src={pciente} />
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                {submitted && !this.state.phoneValid && (
                  <div
                    className={classNames(
                      classes.alertText,
                      classes.alertTextBottom
                    )}
                  >
                    {phone.text}
                  </div>
                )}
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={width > 900 ? 8 : 0}>
            <Grid
              item
              style={{ float: 'left' }}
              className={classNames(classes.inputMobile, 'form-group')}
            >
              <Autocomplete
                id='combo-box-demo'
                options={countries}
                defaultValue={''}
                value={{
                  code: this.state.user.codPais ? this.state.user.codPais : '',
                  name: this.state.user.pais ? this.state.user.pais : '',
                }}
                onChange={this.handleChangePais}
                getOptionLabel={(pais) => pais.name}
                getOptionSelected={(option) =>
                  (option.code = this.state.user.codPais)
                }
                renderInput={(params) => (
                  <TextField {...params} label='País' fullWidth />
                )}
              />
            </Grid>
            {!(
              this.state.user.pais === 'Brasil' ||
              this.state.user.pais === 'Brazil' ||
              this.state.user.pais === 'United States of America' ||
              this.state.user.pais === 'Estados Unidos'
            ) && (
              <Grid
                item
                style={{ float: 'right' }}
                className={classNames(classes.inputMobile, 'form-group')}
              >
                <Autocomplete
                  id='combo-box-demo'
                  options={regions}
                  value={{
                    region: this.state.user.regiao,
                  }}
                  onChange={this.handleChangeRegiao}
                  getOptionLabel={(region) => region.region}
                  getOptionSelected={(region) => region.region}
                  renderInput={(params) => (
                    <TextField
                      onBlur={(evt) => {
                        this.handleChangeRegiaoKeyDown(evt);
                        this.validationCadastro('regiao');
                      }}
                      {...params}
                      label='Estado'
                      fullWidth
                    />
                  )}
                />
              </Grid>
            )}
            {(this.state.user.pais === 'Brasil' ||
              this.state.user.pais === 'Brazil' ||
              this.state.user.pais === 'United States of America' ||
              this.state.user.pais === 'Estados Unidos') && (
              <Grid
                item
                style={{ float: 'right' }}
                className={classNames(classes.inputMobile, 'form-group')}
              >
                <Autocomplete
                  id='combo-box-demo'
                  options={regions}
                  value={{
                    region: this.state.user.regiao,
                  }}
                  onChange={this.handleChangeRegiao}
                  getOptionLabel={(region) => region.region}
                  getOptionSelected={(region) => region.region}
                  renderInput={(params) => (
                    <TextField {...params} label='Estado' fullWidth />
                  )}
                />
                {submitted &&
                  (!this.state.user.regiao ||
                    this.state.user.regiao == 'nda') &&
                  (this.state.user.pais === 'Brasil' ||
                    this.state.user.pais === 'Brazil' ||
                    this.state.user.pais === 'United States of America' ||
                    this.state.user.pais === 'Estados Unidos') && (
                    <div className={classes.alertText}>Campo obrigatório.</div>
                  )}
              </Grid>
            )}
          </Grid>

          {this.state.user.regiao &&
            !(
              this.state.user.pais === 'Brasil' ||
              this.state.user.pais === 'Brazil'
            ) &&
            this.state.user.regiao.length > 0 &&
            this.state.user.regiao != 'nda' && (
              <Autocomplete
                id='combo-box-demo'
                value={{
                  Nome: this.state.user.city ? this.state.user.city : '',
                }}
                options={cities}
                onChange={this.handleChangeCity}
                getOptionLabel={(result) => result.Nome}
                getOptionSelected={(result) => result.Nome}
                renderInput={(params) => (
                  <TextField
                    onBlur={(evt) => {
                      this.handleChangeCidadeKeyDown(evt);
                    }}
                    {...params}
                    label='Cidade'
                    fullWidth
                  />
                )}
              />
            )}

          {this.state.user.regiao &&
            (this.state.user.pais === 'Brasil' ||
              this.state.user.pais === 'Brazil') &&
            this.state.user.regiao.length > 0 &&
            this.state.user.regiao != 'nda' && (
              <Autocomplete
                id='combo-box-demo'
                value={{ Nome: this.state.user.city }}
                options={cities}
                onChange={this.handleChangeCity}
                getOptionLabel={(result) => result.Nome}
                getOptionSelected={(result) => result.Nome}
                renderInput={(params) => (
                  <TextField
                    ref={this.brazilianRef}
                    {...params}
                    label='Cidade'
                    fullWidth
                  />
                )}
              />
            )}

          <Grid container spacing={width > 900 ? 8 : 0}>
            <Grid item xs={12} sm={7}>
              {inputs_3.map((questao) => (
                <div
                  key={questao.type}
                  style={{ width: '100%' }}
                  className={
                    'form-group' +
                    (submitted && !questao.state ? ' has-error' : '')
                  }
                >
                  <TextField
                    label='E-mail'
                    margin='normal'
                    name={questao.type}
                    value={questao.state}
                    className={classes.input}
                    placeholder={questao.pergunta}
                    onBlur={questao.blur}
                    type={questao.date}
                    onFocus={questao.focus}
                    onChange={this.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <img
                            className={
                              questao.imagem
                                ? classes.img_inputs
                                : classes.img_inputs_sem
                            }
                            src={questao.imagem ? questao.imagem : null}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {submitted && !questao.state && (
                    <div
                      className={classNames(
                        classes.alertText,
                        classes.alertTextTop
                      )}
                    >
                      Campo obrigatório.
                    </div>
                  )}
                  {questao.nValid && !(submitted && !questao.state) && (
                    <div
                      className={classNames(
                        classes.alertText,
                        classes.alertTextTop
                      )}
                    >
                      {questao.validacao}
                    </div>
                  )}
                </div>
              ))}
            </Grid>
            <Grid item xs={12} sm={5}>
              {inputs_5.map((questao) => (
                <div
                  key={questao.type}
                  style={{ width: '100%' }}
                  className={
                    'form-group' +
                    (submitted && !questao.state ? ' has-error' : '')
                  }
                >
                  <TextField
                    label={questao.pergunta}
                    margin='normal'
                    name={questao.type}
                    value={questao.state}
                    className={classes.input}
                    placeholder={questao.pergunta}
                    onBlur={questao.blur}
                    type={questao.date}
                    onFocus={questao.focus}
                    onChange={this.handleChange}
                    startAdornment={
                      <InputAdornment position='start'>
                        <img
                          className={
                            questao.imagem
                              ? classes.img_inputs
                              : classes.img_inputs_sem
                          }
                          src={questao.imagem ? questao.imagem : null}
                        />
                      </InputAdornment>
                    }
                  />
                </div>
              ))}
            </Grid>
          </Grid>

          {!aliasConvenio && (
            <Grid container justify={'center'} style={{ padding: '20px 0' }}>
              {(userLogin.user.id_perfil.descricao !=
                'Empresa - Administrativo' || userLogin.user.id_perfil.descricao != 'Empresa - Administrativo 2') && (
                <Fab
                  onClick={this.handleBack}
                  variant='extended'
                  aria-label='Delete'
                  className={classes.fab}
                  color='primary'
                  style={{
                    fontSize: '10px',
                    width: '30%',
                    height: '36px',
                    background: 'white',
                    color: 'red',
                    boxShadow: 'none',
                    border: '1px solid',
                    margin: '1%',
                  }}
                >
                  Cancelar
                </Fab>
              )}
              <Fab
                onClick={this.handleClear}
                variant='extended'
                aria-label='Delete'
                className={classes.fab}
                color='primary'
                style={{
                  fontSize: '10px',
                  width: '30%',
                  height: '36px',
                  background: 'white',
                  color: 'blue',
                  boxShadow: 'none',
                  border: '1px solid',
                  margin: '1%',
                }}
              >
                Limpar
              </Fab>

              <Fab
                variant='extended'
                aria-label='Delete'
                className={classes.fab}
                color='primary'
                style={{
                  fontSize: '10px',
                  width: '30%',
                  height: '36px',
                  background:
                    'linear-gradient(135deg, rgba(20,51,123,1) 0%, rgba(26,68,134,1) 22%, rgba(77,170,155,1) 73%, rgba(86,186,167,1) 100%)',
                  boxShadow: 'none',
                  border: '1px white solid',
                  marginRight: '5px',
                  margin: '1%',
                }}
                type={'submit'}
              >
                Cadastrar
              </Fab>
            </Grid>
          )}
          {aliasConvenio && (
            <Grid container justify={'center'} style={{ padding: '20px 0' }}>
              <Fab
                onClick={this.handleBack}
                variant='extended'
                aria-label='Delete'
                className={classes.fab}
                color='primary'
                style={{
                  fontSize: '10px',
                  width: '30%',
                  height: '36px',
                  background: 'white',
                  color: 'red',
                  boxShadow: 'none',
                  border: '1px solid',
                  margin: '1%',
                }}
              >
                Cancelar
              </Fab>

              <Fab
                onClick={(event) => this.handleValidFormulario(event, true)}
                variant='extended'
                aria-label='Delete'
                className={classes.fab}
                color='primary'
                style={{
                  fontSize: '10px',
                  width: '30%',
                  height: '36px',
                  background: 'white',
                  color: 'blue',
                  boxShadow: 'none',
                  border: '1px solid',
                  margin: '1%',
                }}
              >
                Salvar e Sair
              </Fab>

              <Fab
                variant='extended'
                aria-label='Delete'
                className={classes.fab}
                color='primary'
                style={{
                  fontSize: '10px',
                  width: '30%',
                  height: '36px',
                  background:
                    'linear-gradient(135deg, rgba(20,51,123,1) 0%, rgba(26,68,134,1) 22%, rgba(77,170,155,1) 73%, rgba(86,186,167,1) 100%)',
                  boxShadow: 'none',
                  border: '1px white solid',
                  marginRight: '5px',
                  margin: '1%',
                }}
                type={'submit'}
              >
                Confirmar
              </Fab>
            </Grid>
          )}
        </form>
        {registering && (
          <div className={classes.overlay}>
            <div className='loader'>Loading...</div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { registering } = state.registration;
  const { editAcionamento } = state.editAcionamento;
  const { convenios } = state.convenios;
  const { regions } = state.region;
  const userLogin = state.authentication.user;
  const { countries } = state.country;
  const { cities } = state.citie;
  const { message, type, open, loading } = state.alert;

  return {
    userLogin,
    registering,
    editAcionamento,
    message,
    type,
    open,
    loading,
    convenios,
    countries,
    regions,
    cities,
  };
}

Acionamento.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedRegisterPage = connect(mapStateToProps)(Acionamento);
export default withStyles(styles)(connectedRegisterPage);
