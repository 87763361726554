export const redirectUserByData = (userData: Record<string, any>) => {
  const userProfile = userData?.user?.id_perfil?.descricao;

  if (['Gerador de Link', 'Contingência'].includes(userProfile)) {
    return '/contingencia';
  }

  if (
    [
      'Gestor',
      'Gestor da Unidade de Negócio',
      'Administrativo',
      'Suporte',
    ].includes(userProfile)
  ) {
    return '/adm';
  }

  if (userProfile === 'Empresa - Administrativo' || userProfile === 'Empresa - Administrativo 2') {
    return '/painel';
  }

  if (userProfile === 'Assistencial') {
    return '/adm';
  }

  return null;
};
