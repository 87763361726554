import { Container, Input, Label } from './styles';

export const CheckBox = ({
  type,
  name,
  textLabel,
  disabled,
  margin,
  checked = false,
}) => {
  return (
    <Container margin={margin}>
      <Input
        type={type}
        name={name}
        id={name}
        disabled={disabled}
        checked={checked}
      />
      <Label htmlFor={name}>{textLabel}</Label>
    </Container>
  );
};
