import { useState, useRef, useEffect, useCallback } from 'react';

import { viacepService } from '../../../../../../../Spread/services/core';

import { estados } from '../../../../../../../commons';

import {
  triggerService,
  patientService,
} from '../../../../../../../Spread/services/core';

import {
  formatDate,
  formatLocalHour,
} from '../../../../../../../Spread/utils/format';
import {
  acionamentoActionsVonage,
  alertActions,
} from '../../../../../../../actions';
import { useDispatch } from 'react-redux';
import { history } from '../../../../../../../helpers';
import { acionamentoVonageService } from '../../../../../../../services';
import { pushCallService } from '../../../../../../../services/pushCall.service';

const useEisteinClinicAndCareForm = (editAcionamento) => {
  const dispatch = useDispatch();
  const [useModal, setModal] = useState(false);
  const [useLoading, setLoading] = useState(false);
  const [usePatient, setPatient] = useState(null);
  const [inAttendance, setInAttendance] = useState(true);

  //push call
  const [pushCallModal, setPushCallModal] = useState(false);
  const [pushCallLoading, setPushCallLoading] = useState(false);
  const [pushCallTitle, setPushCallTitle] = useState('');
  const [pushCallMessage, setPushCallMessage] = useState('');
  const [sessionIdState, setSessionIdState] = useState('');
  const [documentState, setDocumentState] = useState('');
  const [pushCallError, setPushCallError] = useState(false);

  const [encounter, setEncounter] = useState({});

  const formValuesRef = useRef(null);

  const handleModal = (state) => {
    setModal(state);
  };

  const searchAddressByCep = async (cep) =>
    await viacepService.getAddressByCep(cep);

  const setFormValues = (values, setFormikValues) => {
    if (values) {
      setFormikValues('country', 'Brasil');
      setFormikValues('state', estados[values?.uf]);
      setFormikValues('city', values?.localidade);
    }
  };

  const sendMailDTO = () => ({
    email: editAcionamento?.email,
    name: editAcionamento?.name,
    lastName: editAcionamento?.lastName,
    socialName: formValuesRef.current.values.socialName,
    url: editAcionamento?.urlAttendanceVonage,
    date: formatDate(editAcionamento?.appointment),
    hour: formatLocalHour(editAcionamento?.appointment),
    unity: editAcionamento?.unit || '',
  });

  const sendMailTrigger = async () => {
    const body = sendMailDTO();
    setLoading(true);
    const response = await triggerService.sendMail(body);
    setLoading(false);
    return response?.status || false;
  };

  const updateTrigger = async (id) => {
    const values = formValuesRef.current.values;
    const response = await triggerService.updateOne(id, {
      ...values,
      streetCep: values.cep,
    });
    return response?.status || false;
  };

  const getPatientData = async () => {
    const response = await patientService.getOneByDocument(
      editAcionamento.document
    );
    const patient = response?.data?.patient || null;
    patient && setPatient(patient);
  };

  const updatePatient = async (body) => {
    const response = await patientService.updateOne(usePatient?._id, body);
    response?.status && getPatientData();
    dispatch(alertActions.success(response.message));
    return response?.status || false;
  };

  async function handleBack(edit = { isVonage: false }) {
    edit.status = 'output';
    await acionamentoActionsVonage
      .updateStatusAttendance({ ...edit, clear: true })
      .then(() => {
        dispatch(acionamentoActionsVonage.clearAcionamentos());
        history.push('/adm');
        window.scroll(0, 0);
      })
      .catch((err) => {
        console.log('erro ao editar', err);
      });
  }

  useEffect(() => {
    getPatientData();
  }, []);

  const fetchEncounter = useCallback(async () => {
    try {
      const data = await acionamentoVonageService.findEncounterById(
        editAcionamento._id
      );
      setEncounter(data.data);
    } catch (e) {
      setPushCallError(false);
    }
  }, []);

  useEffect(() => {
    fetchEncounter();
  }, [fetchEncounter]);

  const pushCall = async (input) => {
    const createPushCall = await pushCallService.createPushCall(input);

    setTimeout(async () => {
      const pushCall = await pushCallService.getById(
        createPushCall.data.callUUID
      );

      const pushCalls = {
        callUUID: pushCall.data.callUUID,
        caller: pushCall.data.caller,
        state: pushCall.data.state,
        createdAt: pushCall.data.created_at,
      };

      setEncounter((prevState) => ({
        ...prevState,
        pushCalls: [...(prevState.pushCalls || []), pushCalls],
      }));

      if (pushCall.data.state === 'CALLING') {
        setPushCallTitle('Ligação não atendida');
        setPushCallMessage(
          'O paciente não atendeu a sua ligação. Deseja tentar novamente?'
        );
        setPushCallLoading(false);
      }
      if (pushCall.data.state === 'ANSWERED') {
        setPushCallTitle('Ligação atendida');
        setPushCallMessage(
          'O paciente atendeu a ligação e está aguardando na videochamada.'
        );
      }
      if (pushCall.data.state === 'DECLINED') {
        setPushCallTitle('Ligação recusada');
        setPushCallMessage(
          'O paciente recusou a sua ligação. Deseja tentar novamente?'
        );
        setPushCallLoading(false);
      }
      if (pushCall.data.state === 'IGNORED') {
        setPushCallTitle('Ligação não atendida');
        setPushCallMessage('O paciente não atendeu. Deseja tentar novamente?');
        setPushCallLoading(false);
      }
    }, 16000);

    setPushCallModal(true);
  };

  const handleCreatePushCall = async () => {
    setPushCallLoading(true);
    setPushCallTitle('Ligando');
    setPushCallMessage('Aguarde, estamos ligando para o paciente.');
    try {
      const pushCallInput = sessionIdState
        ? { sessionId: sessionIdState }
        : { document: documentState };

      await pushCall(pushCallInput);
    } catch (e) {
      if (e.response.data.message) {
        setPushCallTitle('Erro ao realizar ligação');
        setPushCallMessage(e.response.data.message);
        setPushCallLoading(true);
        return;
      }

      setPushCallLoading(false);
      setPushCallTitle('Erro ao realizar ligação');
      setPushCallMessage(
        'Houve um erro para concluir a sua ligação. Deseja tentar novamente?'
      );
    }
  };

  return {
    useLoading,
    handleModal,
    useModal,
    searchAddressByCep,
    setFormValues,
    sendMailTrigger,
    updateTrigger,
    formValuesRef,
    usePatient,
    updatePatient,
    handleBack,
    inAttendance,
    setInAttendance,
    setLoading,
    setSessionIdState,
    setDocumentState,
    pushCall,
    setPushCallLoading,
    setPushCallTitle,
    setPushCallMessage,
    setPushCallModal,
    pushCallModal,
    pushCallLoading,
    pushCallTitle,
    pushCallMessage,
    handleCreatePushCall,
    pushCallError,
    setPushCallError,
    encounter,
  };
};

export default useEisteinClinicAndCareForm;
