import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import iconconvenio from '../icons/icon_convenio.svg';
import data from '../icons/icon_data.svg';
import pciente from '../icons/icon_paciente.svg';
import sexo from '../icons/icon_gen.svg';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { connect } from 'react-redux';
import { validations } from '../helpers/validations';

import {
  alertActions,
  modalAlertActions,
  acionamentoActions,
  acionamentoSuporteActions,
  acionamentoActionsVonage,
  actionForwarding,
} from '../actions';

import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Fab from '@material-ui/core/Fab';
import './App.css';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Alert from './Alert';
import classNames from 'classnames';
import { history } from '../helpers';
import InputMask from 'react-input-mask';
import moment from 'moment';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import DevicesIcon from '@material-ui/icons/Devices';
import ModalAlert from '../components/Lib/ModalAlert';
import { floatToString } from '../utils/utils';

const styles = (theme) => ({
  main: {
    padding: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '700px',
    width: '100%',
    fontFamily: 'Poppins',
    paddingTop: '0',
    margin: '0 auto 0 auto',
  },
  input: {
    width: '100%',
    padding: '8px 0 8px 0',
    fontSize: '15px',
  },
  img_inputs: {
    top: '10px !important',
    width: '20px',
    height: '20px',
  },
  img_inputs_sem: {
    width: '0 !important',
    height: '0 !important',
  },
  formulario: {
    margin: 0,
    padding: 0,
    width: '100%',
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '10000',
    backgroundPosition: 'center center no-repeat',
  },
  inputMobile: {
    width: '50%',
    '@media only screen and (max-width: 900px)': {
      width: '100%',
    },
  },
  inputBirth: {
    width: '100%',
    padding: '8px 0 0 0',
    fontSize: '12px',
    '#::placeholder': {
      fontSize: '12px',
    },
  },
  input_phone: {
    fontSize: '12px',
    padding: '10px 0 2px 0',
    '#::placeholder': {
      fontSize: '12px',
    },
    '@media only screen and (max-width: 900px)': {
      width: '100%',
    },
  },
  formControl: {
    margin: '8px 0',
  },
});

class AcionamentoSuporte extends React.Component {
  constructor(props) {
    props.dispatch(acionamentoActions.getRegionsByCountry());
    props.dispatch(acionamentoActions.getCityByRegions());
    props.dispatch(acionamentoActions.getAllCountryAcionamentos());
    props.dispatch(acionamentoActions.getAllConvenios());
    super(props);
    let dataDocs = {};
    this.state = {
      birthDate: '    -    ',
      numberformat: '1320',
      showCard: false,
      loading: false,
      signedRGURL: '',
      urgencyAttendance: false,
      isDependent: false,
      inputValueReason: '',
      modalDeleteAcionamento: false,
      newOpen: false,
      showAttendanceVonage: false,
      openModalAlert: false,
      user: {
        passage: false,
        userLogin: {},
        cpfrequire: false,
        ceprequire: false,
        name: '',
        editionTime: '',
        lastName: '',
        socialName: '',
        cpf: '',
        email: '',
        birthDate: '',
        startTime: '',
        password: '',
        cpassword: '',
        loadingsub: false,
        checkTerms: false,
        showNotifications: false,
        numberCard: '',
        userType: '',
        sex: '',
        phone: '',
        phone2: '',
        phone3: '',
        rg: '',
        passport: '',
        convenio: '',
        nacionalidade: '',
        pais: '',
        codPais: '',
        city: '',
        id_requisicao_parceiro: '',
        regiao: '',
        flgExistPassage: false,
        convenioDescricao: '',
        streetCep: '',
        cep: '',
        deviceData: {
          deviceType: '',
          OS: '',
          navigator: '',
          route: '',
          accessChannel: '',
          appVersion: '',
          deviceBrand: '',
        },
        signUpDocuments: [],
        docsReferences: [],
      },
      country: {
        result: [],
      },
      aliasConvenio: null,
      message: '',
      type: '',
      open: false,
      vertical: 'top',
      horizontal: 'center',
      valDate: 'text',
      registry: false,
      formValid: false,
      dataDocs: dataDocs,
      supportCompleted: false,
      supportNotCompleted: false,
      generalObservations: '',
    };
    this.TextMaskCustom = this.TextMaskCustom.bind(this);
    this.PhoneMask = this.PhoneMask.bind(this);
    this.PhoneMask2 = this.PhoneMask2.bind(this);
    this.PhoneMask3 = this.PhoneMask3.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleValidFormulario = this.handleValidFormulario.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleChangeSupportStatus = this.handleChangeSupportStatus.bind(this);
    this.handleChangeGeneralObservations =
      this.handleChangeGeneralObservations.bind(this);
    this.handleEditAcionamentoSupport =
      this.handleEditAcionamentoSupport.bind(this);
    this.handleSubmitSupport = this.handleSubmitSupport.bind(this);
    this.TextMaskCustomCPF = this.TextMaskCustomCPF.bind(this);
    this.handleCloseSupport = this.handleCloseSupport.bind(this);
    this.waitSupport = this.waitSupport.bind(this);
    this.handleChangeStatusSupportWait =
      this.handleChangeStatusSupportWait.bind(this);
  }

  handleChangeGeneralObservations(e) {
    const { value } = e.target;
    this.setState({ generalObservations: value });
  }

  handleChangeSupportStatus(e) {
    const { name, checked } = e.target;
    const checkObjectStatus = {
      supportCompleted: () =>
        this.setState({
          supportCompleted: checked,
          supportNotCompleted: false,
        }),
      supportNotCompleted: () =>
        this.setState({
          supportCompleted: false,
          supportNotCompleted: checked,
        }),
    };
    checkObjectStatus[name]();
  }

  handleChangeStatusSupportWait = async () => {
    const { user } = this.state;

    this.waitSupport(user);
    this.handleBack();
  };

  waitSupport = (data) => {
    const objectForFoward = {
      origin: {
        channel: 'acionamentos',
        received: 'central',
        from: '',
      },
      originProfessional: {
        name: '',
        id: '',
      },
    };

    const locallUser = JSON.parse(localStorage.getItem('user'));
    objectForFoward.origin.from = locallUser?.user?.id_perfil?.descricao;
    objectForFoward.originProfessional.name = '';
    objectForFoward.originProfessional.id = locallUser.user._id;
    const dataForFowarding = { ...data, ...objectForFoward };

    this.props.dispatch(actionForwarding.noActionSupport(dataForFowarding));
  };

  componentWillUnmount() {
    const { supportCompleted, supportNotCompleted } = this.state;

    if (!supportCompleted && !supportNotCompleted)
      this.handleChangeStatusSupportWait();
  }

  async componentDidMount() {
    this.props.dispatch(acionamentoActions.getAllCountryAcionamentos());
    this.props.dispatch(acionamentoActions.getCityByRegions());
    this.props.dispatch(acionamentoActions.getRegionsByCountry());

    const { supportAcionamento } = this.props;
    let user = supportAcionamento;

    if (user.name) {
      let {
        deviceData = {
          deviceType: 'Não Recebido',
          OS: '',
          navigator: '',
          route: '/',
          accessChannel: '',
          appVersion: '',
          deviceBrand: '',
        },
      } = user;
      let { streetCep = '', signUpDocuments = [], docsReferences = [] } = user;
      let nacUser =
        user.document && !validations.validationCPF(user.document) ? 'B' : 'E';

      if (!user.document) nacUser = 'B';

      this.setState({
        aliasConvenio: true,
        deletedDocs: this.props.deletedDocs,
        showAttendanceVonage: true,
        loadSup: false,
        user: {
          ...supportAcionamento,
          userLogin: this.props.userLogin,
          editionTime: user.editionTime,
          convenio: `${user.convenio}|${
            user.contrato ? user.contrato : user.convenioDescricao
          }`,
          convenioDescricao: user.contrato
            ? user.contrato
            : user.convenioDescricao,
          contrato: user.contrato ? user.contrato : user.convenioDescricao,
          cpfrequire: false,
          ceprequire: false,
          name: user.name,
          lastName: user.lastName,
          socialName: user.socialName,
          cpf: user.document,
          email: user.email,
          birthDateConfimation: user.birthDate,
          birthDate: moment(user.birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          password: '',
          cpassword: '',
          loadingsub: false,
          checkTerms: false,
          showNotifications: false,
          sex: user.gender,
          phone: floatToString(user.phone),
          phone2: floatToString(user.homePhone),
          phone3: floatToString(user.phone2),
          rg: user.rg,
          passport: user.document,
          nacionalidade: nacUser,
          numberCard: user.numberCard,
          startTime: user.startTime,
          userType: user.userType,
          city: user.city,
          regiao: user.state,
          codPais: user.codPais,
          pais: user.country,
          id_requisicao_parceiro: user.partnerID,
          streetCep: streetCep,
          deviceData: deviceData,
          signUpDocuments: signUpDocuments,
          docsReferences: docsReferences,
          urlAttendanceVonage: user.urlAttendanceVonage,
          idAttendanceVonage: user.idAttendanceVonage,
          dataDocs: {},
        },
      });
    }
  }

  TextMaskCustomCPF(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        value={this.state.user.cpf}
        onChange={this.handleChange}
        style={{ fontSize: '15px', verticalAlign: 'top' }}
        name='cpf'
        mask='999.999.999-99'
        maskChar={null}
        // beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  handleCloseSupport() {
    this.props.dispatch(modalAlertActions.clear());
    this.setState({ openModalSupport: false, loadSup: true });
    history.push('/adm');
  }

  handleValidFormulario(event) {
    event.preventDefault();
    const { supportCompleted, supportNotCompleted } = this.state;
    const error =
      [supportCompleted, supportNotCompleted].filter((checkValue) => checkValue)
        .length !== 1;
    if (!error) {
      this.handleSubmitSupport();
    }
  }

  handleSubmitSupport = async () => {
    this.setState({ loadSup: true });
    let local = JSON.parse(localStorage.getItem('user'));
    const Professional = {
      name: local.user.nome,
      id: local.user._id,
      email: local.user.email,
    };
    const { user, supportNotCompleted } = this.state;
    user.birthDate = user.birthDateConfimation;

    await acionamentoActionsVonage.updateStatusConecta(
      user,
      'recepcao',
      local.token,
      Professional,
      supportNotCompleted
    );
    this.handleEditAcionamentoSupport(user._id);
  };

  handleClose() {
    this.props.dispatch(modalAlertActions.clear());
    this.setState({ open: false, message: '', loadingsub: false });
  }

  handleEditAcionamentoSupport = (id) => {
    this.setState({ loadSup: false });
    let { generalObservations, supportCompleted } = this.state;
    this.props.dispatch(
      acionamentoSuporteActions.editAcionamentosSupport(
        id,
        generalObservations,
        supportCompleted,
        this.handleBack
      )
    );
  };

  TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        value={this.state.user.birthDate}
        style={{ fontSize: '15px', verticalAlign: 'top' }}
        name='birthDate'
        id='formatted-text-mask-input'
        placeholder='Data de Nascimento'
        mask='99/99/9999'
        maskChar={null}
        // beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  handleBack() {
    history.push('/adm');
  }

  PhoneMask(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        key={'phone'}
        style={{ fontSize: '15px', verticalAlign: 'middle' }}
        name={'phone'}
        value={this.state.user.phone}
        placeholder={'DDD + Telefone'}
        mask={
          this.state.user.nacionalidade === 'B'
            ? '(99) 999999999'
            : '999999999999999'
        }
        maskChar={null}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }
  PhoneMask2(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        key={'phone2'}
        style={{ fontSize: '15px', verticalAlign: 'middle' }}
        name={'phone2'}
        value={this.state.user.phone2}
        placeholder={'DDD + Telefone 2'}
        mask={
          this.state.user.nacionalidade === 'B'
            ? '(99) 999999999'
            : '999999999999999'
        }
        maskChar={null}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }
  PhoneMask3(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        key={'phone3'}
        style={{ fontSize: '15px', verticalAlign: 'middle' }}
        name={'phone3'}
        placeholder={'Telefone 3'}
        value={this.state.user.phone3}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (
      value.endsWith('-') &&
      userInput !== '-' &&
      !this.state.value.endsWith('-')
    ) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  };

  handleOpenLink = () => {
    let userData = this.state.user;
    userData.date = userData.birthDate;
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    let confirmedTime = new Date(Date.now() - tzoffset).toISOString();
    if (userData.convenio !== '' && userData.name) {
      this.props.dispatch(
        acionamentoActionsVonage.attendanceAcionamentosSuport(
          { ...userData, confirmedTime },
          this
        )
      );
    }
  };

  render() {
    const {
      registering,
      open,
      classes,
      message,
      type,
      convenios = [],
      countries = [],
      cities = [],
      openModalAlert,
      messageModalAlert,
      typeModalAlert,
    } = this.props;
    let { regions } = this.props;

    const {
      user,
      aliasConvenio,
      showCard,
      signedRGURL,
      modalDeleteAcionamento,
      inputValueReason,
      supportCompleted,
      supportNotCompleted,
      showAttendanceVonage,
    } = this.state;

    const inputs_4 = [
      {
        imagem: iconconvenio,
        pergunta: 'Passaporte',
        state: user.passport,
        type: 'passport',
        nValid: this.state.passportValid,
        validacao: 'Passaporte inválido.',
      },
    ];

    const error =
      [supportCompleted, supportNotCompleted].filter((checkValue) => checkValue)
        .length !== 1;

    const width = window.innerWidth;
    if (regions.length >= 1) {
      regions = regions[0].states;
    }
    const inputs_1 = [
      {
        float: 'left',
        imagem: pciente,
        pergunta: 'Nome',
        state: user.name,
        type: 'name',
      },
      {
        float: 'right',
        imagem: pciente,
        pergunta: 'Sobrenome Completo',
        state: user.lastName,
        type: 'lastName',
      },
    ];

    const inputs_3 = [
      {
        imagem: pciente,
        pergunta: 'Email',
        state: user.email,
        type: 'email',
      },
    ];

    const inputs_5 = [
      {
        imagem: iconconvenio,
        pergunta: 'Identificador parceiro',
        state: user.id_requisicao_parceiro,
        type: 'id_requisicao_parceiro',
      },
    ];

    const phones = [
      {
        inputRef: this.PhoneMask,
        float: 'left',
        text: 'Campo obrigatório.',
      },
      {
        inputRef: this.PhoneMask2,
        float: 'center',
      },
      {
        inputRef: this.PhoneMask3,
        float: 'right',
      },
    ];
    const convenioSplit = user.convenio.split('|');
    return (
      <div className={classes.main}>
        <Alert
          open={open}
          type={type}
          message={message}
          onClose={() => this.handleClose(type, message)}
        />
        <form
          id='Cadastro_Usuario'
          name='Cadastro_Usuario'
          className={classes.formulario}
          onSubmit={this.handleValidFormulario}
        >
          <TextField
            id='standard-select-currency'
            label='Fila'
            disabled={true}
            margin='normal'
            value={user.descricaoFila}
            displayEmpty
            name='descricaoFila'
            className={classes.input}
            placeholder='descrição da Fila'
          ></TextField>
          <TextField
            id='standard-select-currency'
            select
            label='Convênio'
            disabled={true}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin='normal'
            value={user.convenio}
            input={<Input id='select-multiple-placeholder' />}
            displayEmpty
            name='convenio'
            className={classes.input}
          >
            {aliasConvenio ? (
              <MenuItem
                key={convenioSplit[0]}
                value={`${convenioSplit[0]}|${convenioSplit[1]}`}
              >
                {convenioSplit[1]}
              </MenuItem>
            ) : (
              convenios.map((convenio) => (
                <MenuItem
                  key={convenio.id}
                  value={`${convenio.id}|${convenio.descricao}`}
                >
                  {convenio.descricao}
                </MenuItem>
              ))
            )}
          </TextField>

          <Grid container spacing={width > 900 ? 8 : 0}>
            {inputs_1.map((questao) => (
              <Grid
                key={questao.type}
                item
                style={{ float: questao.float }}
                className={classNames(classes.inputMobile)}
              >
                <TextField
                  margin='normal'
                  placeholder={questao.validacao}
                  label={questao.pergunta}
                  name={questao.type}
                  value={questao.state}
                  className={classes.input}
                  key={questao.type}
                  type={questao.date}
                  onFocus={questao.focus}
                  disabled={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img
                          className={
                            questao.imagem
                              ? classes.img_inputs
                              : classes.img_inputs_sem
                          }
                          src={questao.imagem ? questao.imagem : null}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ))}
          </Grid>

          {user?.socialName && (
            <TextField
              label='Nome Social'
              disabled={true}
              margin='normal'
              value={user.socialName}
              displayEmpty
              name='descricaoFila'
              className={classes.input}
              placeholder='Nome social.'
            ></TextField>
          )}

          <Select
            value={user.nacionalidade}
            input={<Input id='select-multiple-placeholder' />}
            displayEmpty
            name='nacionalidade'
            className={classes.input}
            disabled={true}
            startAdornment={
              <InputAdornment position='start'>
                <img className={classes.img_inputs} src={sexo} />
              </InputAdornment>
            }
          >
            <MenuItem value=''>
              <em style={{ opacity: '0.5', fontStyle: 'Normal' }}>
                Nacionalidade
              </em>
            </MenuItem>
            <MenuItem value={'B'}>Brasileira</MenuItem>
            <MenuItem value={'E'}>Estrangeira</MenuItem>
          </Select>

          {user.nacionalidade === 'B' ? (
            <Grid>
              <TextField
                label='CPF'
                margin='normal'
                placeholder='Digite o CPF.'
                className={classes.inputBirth}
                disabled={true}
                InputProps={{
                  inputComponent: this.TextMaskCustomCPF,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img className={classes.img_inputs} src={iconconvenio} />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
          ) : (
            inputs_4.map((questao) => {
              return (
                <TextField
                  label='Passaporte'
                  margin='normal'
                  name={questao.type}
                  value={questao.state}
                  className={classes.input}
                  placeholder={questao.pergunta}
                  disabled={true}
                  type={questao.date}
                  onFocus={questao.focus}
                  onChange={this.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img
                          className={
                            questao.imagem
                              ? classes.img_inputs
                              : classes.img_inputs_sem
                          }
                          src={questao.imagem ? questao.imagem : null}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              );
            })
          )}

          <Grid container spacing={width > 900 ? 8 : 0}>
            <Grid
              item
              style={{ float: 'left' }}
              className={classNames(classes.inputMobile, 'form-group')}
            >
              <TextField
                label='Data de Nascimento'
                margin='normal'
                className={classes.inputBirth}
                disabled={true}
                InputProps={{
                  inputComponent: this.TextMaskCustom,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img className={classes.img_inputs} src={data} />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>

            <Grid
              item
              style={{ float: 'right' }}
              className={classNames(classes.inputMobile, 'form-group')}
            >
              <TextField
                id='standard-select-currency'
                select
                label='Sexo'
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin='normal'
                value={
                  user.sex === 'Masculino'
                    ? 'M'
                    : user.sex === 'Feminino'
                    ? 'F'
                    : user.sex
                }
                input={<Input id='select-multiple-placeholder' />}
                displayEmpty
                name='sex'
                className={classes.input}
                disabled={true}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img className={classes.img_inputs} src={sexo} />
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value={'M'}>Masculino</MenuItem>
                <MenuItem value={'F'}>Feminino</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            {phones.map((phone) => (
              <Grid key={phone.float} item xs={12} sm={4}>
                <TextField
                  label='Telefone'
                  margin='normal'
                  className={classes.input_phone}
                  disabled={true}
                  InputProps={{
                    inputComponent: phone.inputRef,
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img className={classes.img_inputs} src={pciente} />
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={width > 900 ? 8 : 0}>
            <Grid item style={{ width: '100%' }}>
              <TextField
                label='Canal de acesso'
                margin='normal'
                value={
                  this.state.user.deviceData.deviceType +
                  ' ' +
                  this.state.user.deviceData.accessChannel +
                  ' ' +
                  this.state.user.deviceData.OS
                }
                disabled={true}
                style={{
                  width: '100%',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <DevicesIcon />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container spacing={width > 900 ? 8 : 0}>
            <Grid item xs={12} sm={7}>
              {inputs_3.map((questao) => (
                <div
                  key={questao.type}
                  style={{ width: '100%' }}
                  className={'form-group'}
                >
                  <TextField
                    label='E-mail'
                    margin='normal'
                    name={questao.type}
                    value={questao.state}
                    className={classes.input}
                    placeholder={questao.pergunta}
                    type={questao.date}
                    onFocus={questao.focus}
                    disabled={true}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <img
                            className={
                              questao.imagem
                                ? classes.img_inputs
                                : classes.img_inputs_sem
                            }
                            src={questao.imagem ? questao.imagem : null}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              ))}
            </Grid>
            <Grid item xs={12} sm={5}>
              {inputs_5.map((questao) => (
                <div
                  key={questao.type}
                  style={{ width: '100%' }}
                  className={'form-group'}
                >
                  <TextField
                    label={questao.pergunta}
                    margin='normal'
                    name={questao.type}
                    value={questao.state}
                    className={classes.input}
                    placeholder={questao.pergunta}
                    type={questao.date}
                    onFocus={questao.focus}
                    disabled={true}
                    startAdornment={
                      <InputAdornment position='start'>
                        <img
                          className={
                            questao.imagem
                              ? classes.img_inputs
                              : classes.img_inputs_sem
                          }
                          src={questao.imagem ? questao.imagem : null}
                        />
                      </InputAdornment>
                    }
                  />
                </div>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl
              required
              error={error}
              component='fieldset'
              className={classes.formControl}
            >
              <FormLabel component='legend'>Suporte</FormLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={supportCompleted}
                      onChange={this.handleChangeSupportStatus}
                      name='supportCompleted'
                      color='default'
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                      icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                      checkedIcon={<CheckBoxIcon fontSize='small' />}
                    />
                  }
                  label='Concluído'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={supportNotCompleted}
                      onChange={this.handleChangeSupportStatus}
                      name='supportNotCompleted'
                      color='default'
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                      icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                      checkedIcon={<CheckBoxIcon fontSize='small' />}
                    />
                  }
                  label='Não concluído'
                />
              </FormGroup>
              <FormHelperText>
                *Selecione um status para o suporte!
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid container spacing={width > 900 ? 8 : 0}>
            <Grid item style={{ width: '100%' }}>
              <TextField
                label='Observações gerais'
                margin='normal'
                onChange={(event) =>
                  this.handleChangeGeneralObservations(event)
                }
                value={this.state.generalObservations}
                style={{
                  width: '100%',
                }}
              ></TextField>
            </Grid>
          </Grid>

          {user.idAttendanceVonage && showAttendanceVonage && (
            <Grid
              container
              justify={'center'}
              style={{ padding: '20px 0', marginTop: '50px' }}
            >
              <Fab
                onClick={this.handleOpenLink}
                variant='extended'
                aria-label='Delete'
                className={classes.fab}
                color='primary'
                style={{
                  fontSize: '10px',
                  width: '30%',
                  height: '36px',
                  background:
                    'linear-gradient(135deg, rgba(20,51,123,1) 0%, rgba(26,68,134,1) 22%, rgba(77,170,155,1) 73%, rgba(86,186,167,1) 100%)',
                  boxShadow: 'none',
                  border: '1px white solid',
                  marginRight: '5px',
                  margin: '1%',
                }}
              >
                <VideoCallIcon /> Entrar na chamada
              </Fab>
            </Grid>
          )}

          <Grid
            container
            justify={'center'}
            style={{ padding: '20px 0', marginTop: '50px' }}
          >
            <Fab
              onClick={this.handleChangeStatusSupportWait}
              variant='extended'
              aria-label='Delete'
              className={classes.fab}
              color='primary'
              style={{
                fontSize: '10px',
                width: '30%',
                height: '36px',
                background: 'white',
                color: 'red',
                boxShadow: 'none',
                border: '1px solid',
                margin: '1%',
              }}
            >
              Cancelar
            </Fab>
            <Fab
              onClick={(event) => this.handleValidFormulario(event)}
              variant='extended'
              aria-label='Delete'
              className={classes.fab}
              color='primary'
              style={{
                fontSize: '10px',
                width: '30%',
                height: '36px',
                background:
                  'linear-gradient(135deg, rgba(20,51,123,1) 0%, rgba(26,68,134,1) 22%, rgba(77,170,155,1) 73%, rgba(86,186,167,1) 100%)',
                boxShadow: 'none',
                border: '1px white solid',
                marginRight: '5px',
                margin: '1%',
              }}
              type={'submit'}
            >
              Confirmar
            </Fab>
          </Grid>
        </form>
        {registering && (
          <div className={classes.overlay}>
            <div className='loader'>Loading...</div>
          </div>
        )}
        {this.state.loadSup && (
          <div className={classes.overlay}>
            <div className='loader'>Loading...</div>
          </div>
        )}

        <ModalAlert
          open={openModalAlert}
          message={messageModalAlert}
          close={() => this.handleCloseSupport()}
          type={typeModalAlert}
        ></ModalAlert>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { deletedDocs } = state.deletedDocs;
  const userLogin = state.authentication.user;
  const { registering } = state.registration;
  const { supportAcionamento } = state.supportAcionamento;
  const { convenios } = state.convenios;
  const { regions } = state.region;
  const { countries } = state.country;
  const { cities } = state.citie;

  const { message, type, open, loading } = state.alert;

  const { openModalAlert, typeModalAlert, messageModalAlert } =
    state.modalAlert;

  const { user } = state;

  return {
    registering,
    supportAcionamento,
    message,
    type,
    open,
    loading,
    convenios,
    countries,
    regions,
    cities,
    user,
    userLogin,
    deletedDocs,
    openModalAlert,
    typeModalAlert,
    messageModalAlert,
  };
}

AcionamentoSuporte.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedRegisterPage = connect(mapStateToProps)(AcionamentoSuporte);
export default withStyles(styles)(connectedRegisterPage);
